import "@nucleus/polyfill/src/_polyfill";
import { Component, Factory } from "@nucleus/core/src/_core";

export default class LidlRecipesMTagCloudItem extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-m-tag-cloud-item";
    }

    /**
     * Creates an instance of LidlRecipesMTagCloudItem.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);
    }
}

Factory.registerComponent(LidlRecipesMTagCloudItem);
