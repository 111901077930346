/**
 * Extension to make Nunjucks compatible with Symfony by adding missing extensions
 */
export default class SymfonyCompatExtension {

    constructor(nunjucksWrapper) {
        this._nunjucksWrapper = nunjucksWrapper;

        nunjucksWrapper.environment.addGlobal("asset", (path, packageName) => this.asset(path, packageName));
    }

    asset(path, packageName) {
        const assetManifest = this._nunjucksWrapper.getAssetManifest(packageName);

        if (assetManifest) {
            return assetManifest[path];
        } else {
            return path;
        }
    }
}