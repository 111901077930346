import { Component, ComponentFactory } from "@nucleus/core/src/_core";
import { getCookie, setCookie } from "../../../global/lib/cookies";
import userService from "@lidl-recipes/global/lib/user-service";

export default class LidlRecipesMHeaderUserNavItemSso extends Component {

    static get rootClassName() {
        return "lirc-m-header-user-nav-item-sso";
    }

    /**
     * Creates an instance of LidlRecipesMHeaderUserNavItemSso.
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._closeButton = ComponentFactory.getInstance(rootElement.querySelector(".lirc-m-header-user-nav-item-sso__close-button"));
        this._textUnknownUser = ComponentFactory.getInstance(rootElement.querySelector(".lirc-m-header-user-nav-item-sso__text-unknown-user"));
        this._textKnownUser = ComponentFactory.getInstance(rootElement.querySelector(".lirc-m-header-user-nav-item-sso__text-known-user"));
        this._dropdown = ComponentFactory.getInstance(rootElement.querySelector(".lirc-m-header-user-nav-item-sso__dropdown"));
        this._anchor = ComponentFactory.getInstance(rootElement.querySelector(".lirc-m-header-user-nav-item-sso__anchor"));
        this._cookieName = "LidlRecipesMHeaderUserNavItemSso-isKnownUser";
        this._timerId = null;

        let _isSignedInUser = rootElement.hasAttribute("data-user-signed-in");
        let _isKnownUser = !!getCookie(this._cookieName);
        let _dropdownVisible = false;

        this._anchor.clickSubject.subscribe((_,event) => {
            event.preventDefault();
            if (!_dropdownVisible) {
                this._openDropdown();
                _dropdownVisible = true;
                return;
            }
            this._closeDropdown();
            _dropdownVisible = false;
        });

        this._anchor.rootElement.addEventListener('mouseenter', () => {
            this._openDropdown();
        });

        this._dropdown.rootElement.addEventListener('mouseenter', () => {
            clearTimeout(this._timerId);
        });

        this.rootElement.addEventListener('mouseleave', () => {
            this._timerId = setTimeout(() => this._closeDropdown(), 500);
        });

        this._closeButton.clickSubject.subscribe(() => {
            this._closeDropdown();
        });

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                this._closeDropdown();
                _dropdownVisible = false;
            }
        });

        if (_isKnownUser && !_isSignedInUser) {
            this._displayKnownUserMessage();
        } else if (!_isKnownUser && _isSignedInUser) {
            this._setKnownUserCookie();
        }

        if (userService.isSignedIn) {
            userService.logoutSubject.subscribe(() => {
                // The user was logged out so trigger a page reload
                window.location.reload();
            });
        }
    }

    /**
     * @private
     */
    _openDropdown() {
        clearTimeout(this._timerId);
        this._dropdown.show();
        this._anchor.setAttribute('aria-expanded', 'true');
    }

    /**
     * @private
     */
    _closeDropdown() {
        this._dropdown.hide();
        this._anchor.setAttribute('aria-expanded', 'false');
    }
    
    /**
     * @private
     */
    _displayKnownUserMessage() {
        this._textKnownUser.show();
        this._textUnknownUser.hide();
    }

    /**
     * @private
     */
    _setKnownUserCookie() {
        setCookie(this._cookieName, 'true', 366);
    }
}

ComponentFactory.registerComponent(LidlRecipesMHeaderUserNavItemSso);
