import nunjucks from "nunjucks";
import CoreExtension from "./core-extension.js";
import SchemaExtension from "./schema-extension.js";
import DebugExtension from "./debug-extension.js";
import TwigCompatExtension from "./twig-compat-extension.js";
import SymfonyCompatExtension from "./symfony-compat-extension.js";

export default class NunjucksCore {

    /**
     * @param {nunjucks.Loader} loader
     * @param {Object} options
     * @param {Object.<String, String>} options.assetManifestPaths
     * @param {String} options.nucleusManifestPath
     * @param environmentOptions
     */
    constructor(loader, options) {
        if (loader.setCore) {
            loader.setCore(this);
        }

        this._environment = new nunjucks.Environment(loader);
        this._options = options;

        this._loadingPromise = Promise.all([
            this._loadAssetManifest(),
            this._loadNucleusManifest()
        ]);

        this._extensions = [];
        this._extensions.push(new CoreExtension(this));
        this._extensions.push(new SchemaExtension(this));
        this._extensions.push(new DebugExtension(this));
        this._extensions.push(new TwigCompatExtension(this));
        this._extensions.push(new SymfonyCompatExtension(this));
    }

    /**
     * Promise for loading manifest files.
     * @returns {Promise<any>}
     */
    get loadingPromise() {
        return this._loadingPromise;
    }

    /**
     * Return the nunjucks environment.
     *
     * @returns {nunjucks.Environment}
     */
    get environment() {
        return this._environment;
    }

    /**
     * Returns the asset manifest.
     *
     * @param {String} packageName
     * @returns {Object.<String, String>}
     */
    getAssetManifest(packageName) {
        throw new Error("You have to implement the method assetManifest!");
    }

    /**
     * Return the nucleus manifest.
     *
     * @returns {Object}
     */
    getNucleusManifest() {
        throw new Error("You have to implement the method nucleusManifest!");
    }

    /**
     * Loads the asset manifest file.
     *
     * @returns {Promise<any>}
     * @abstract
     * @protected
     */
    _loadAssetManifest() {
        throw new Error("You have to implement the method _loadAssetManifest!");
    }

    /**
     * Loads the nucleus manifest file.
     * @returns {Promise<any>}
     * @abstract
     * @protected
     */
    _loadNucleusManifest() {
        throw new Error("You have to implement the method _loadNucleusManifest!");
    }

    /**
     * Renders the component data.
     * @param {Object} data
     * @return {Promise<string>}
     */
    renderComponent(data) {
        return this._loadingPromise.then(() => {
            return new Promise((resolve, reject) => {
                const componentParts = data.component.split("/");
                const namespace = componentParts[0];
                const component = componentParts[1];

                const template = this.environment.getTemplate("@" + namespace + "/" + component + ".twig");
                template.getExported(function (error, exportedObjects) {
                    if (error) {
                        reject(error);
                        return;
                    }

                    let renderedHTML = exportedObjects.render(data).toString();
                    resolve(renderedHTML);
                });
            });
        });
    }
}
