import "@nucleus/polyfill/src/_polyfill";
import {Component, Factory} from "@nucleus/core/src/_core";
import NucleusAInput from "@nucleus/a-input";
import NucleusASelect from "@nucleus/a-select";
import EventUtils from "@nucleus/core/src/_eventUtils";
import { BREAKPOINTS } from "@nucleus/core/src/_constants";

export default class LidlRecipesOSearchDropdown extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-search-dropdown";
    }

    /**
     * Creates an instance of LidlRecipesOSearchDropdown.
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement, true);

        this._modifiers = {
            root: {
                disabled: "lirc-o-search-dropdown--disabled"
            }
        };

        this._trackingId = this.dataset.trackingId

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._editButton = Factory.createComponent(this.querySelector(".lirc-o-search-dropdown__edit-button"));
        this._editButton.clickSubject.subscribe(() => this._onEditButtonClick());


        /**
         * @type {NucleusAButton}
         * @private
         */
        this._closeButton = Factory.createComponent(this.querySelector(".lirc-o-search-dropdown__close-button"));
        this._closeButton.clickSubject.subscribe(() => this._onCloseButtonClick());

        /**
         * @type {Component}
         * @private
         */
        this._buttonText = Factory.createComponent(this.querySelector(".lirc-o-search-dropdown__button-text"));

        /**
         * @type {Component}
         * @private
         */
        this._placeholder = Factory.createComponent(this.querySelector(".lirc-o-search-dropdown__placeholder"));

        /**
         * @type {Component}
         * @private
         */
        this._editIconWrapper = Factory.createComponent(this.querySelector(".lirc-o-search-dropdown__edit-icon-wrapper"));

        /**
         * @type {Component}
         * @private
         */
        this._bodyComponent = Factory.createComponent(this.querySelector(".lirc-o-search-dropdown__body"));

        /**
         * @type {NucleusAInput[]}
         * @private
         */
        this._inputs = Factory.createComponents(this._bodyComponent.querySelectorAll(NucleusAInput.rootSelector));
        this._inputs.forEach((input) => input.changeSubject.subscribe(this._onFormControlChange));

        /**
         * @type {NucleusASelect[]}
         * @private
         */
        this._selects = Factory.createComponents(this._bodyComponent.querySelectorAll(NucleusASelect.rootSelector));
        this._selects.forEach((select) => select.changeSubject.subscribe(this._onFormControlChange));

        /**
         * @type {boolean}
         * @private
         */
        this._isOpen = false;

        this.rootElement.addEventListener("focusout", event => {
            if (!this.rootElement.contains(event.relatedTarget) && window.innerWidth >= BREAKPOINTS["sm"]) {
                this._close();
                document.activeElement.blur();
            }
        });
    }

    /**
     * @inheritDoc
     */
    _initialize() {
        this._setButtonTextToSelection();
    }

    /**
     * Callback for the edit button click event.
     * @private
     */
    _onEditButtonClick() {
        this._open();
    }

    /**
     * Callback for the close button click event.
     * @private
     */
    _onCloseButtonClick() {
        this._close();
    }

    /**
     * Callback for change event of inputs and selects.
     * @private
     */
    _onFormControlChange = EventUtils.debounce(() => {
        setTimeout(() => {
            this._setButtonTextToSelection();
        }, 0);
    }, 0);

    /**
     * Open the selection dropdown.
     * @protected
     */
    _open() {
        if (!this._isOpen) {
            this._isOpen = true;

            this._editIconWrapper.hide();
            this._closeButton.show();
            this._bodyComponent.show();
            this.addClass("lirc-o-search-dropdown--open")
        }

        this._bodyComponent.focus();
    }

    /**
     * Closes the selection dropdown.
     * @protected
     */
    _close() {
        if (this._isOpen) {
            this._isOpen = false;

            this._editIconWrapper.show();
            this._closeButton.hide();
            this._bodyComponent.hide();
            this._editButton.focus();
            this._setButtonTextToSelection();
            this.removeClass("lirc-o-search-dropdown--open")
        }
    }

    /**
     * Open dropdown.
     * @public
     */
    open() {
        this._open();
    }

    /**
     * Close dropdown.
     * @public
     */
    close() {
        this._close();
    }

    /**
     * Sets the button text to reflect current selection.
     * @protected
     */
    _setButtonTextToSelection() {
        const selectionText = this._getButtonTextToSelection();

        if (selectionText) {
            this._buttonText.rootElement.textContent = selectionText;
            this._buttonText.show();
            this._placeholder.hide();
        } else {
            this._buttonText.hide();
            this._placeholder.show();
        }
    }

    /**
     * Returns the button text to reflect current selection.
     * @param {string} selectionText
     * @return {string|null}
     * @abstract
     * @protected
     */
    _getButtonTextToSelection() {
        return null;
    }

    /**
     * Returns the disabled state of the edit button.
     * @return {boolean}
     */
    get disabled() {
        return this._editButton.disabled;
    }

    /**
     * Sets the disabled state of the edit button.
     * @param {boolean} disabled
     */
    set disabled(disabled) {
        if (typeof disabled === "boolean") {
            this._editButton.disabled = disabled;

            if (disabled) {
                this.addClass(this._modifiers.root.disabled);
            } else {
                this.removeClass(this._modifiers.root.disabled);
            }
        }
    }

    /**
     * Returns placeholder text.
     * @return {string}
     */
    get placeholder() {
        return this._placeholder.rootElement.textContent;
    }

    /**
     * Returns tracking id.
     * @return {string}
     */
    get trackingId() {
        return this._trackingId;
    }

    /**
     * Set placeholder text.
     * @param text
     */
    set placeholder(text) {
        this._placeholder.rootElement.textContent = text;
    }
}

Factory.registerComponent(LidlRecipesOSearchDropdown);
