import "@nucleus/polyfill/src/_polyfill";
import {ComponentFactory, Subject} from "@nucleus/core/src/_core";
import NucleusValidity from "@nucleus/validity";

export default class NucleusAInput extends NucleusValidity {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "nuc-a-input";
    }

    /**
     * Creates an instance of NucleusAInput.
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        /**
         * @type {Element}
         * @private
         */
        this._labelElement = document.querySelector("label[for=\"" + this.rootId + "\"]");

        /**
         * @type {Subject}
         * @private
         */
        this._changeSubject = new Subject();

        /**
         * @type {Subject}
         * @private
         */
        this._focusSubject = new Subject();

        /**
         * @type {Subject}
         * @private
         */
        this._blurSubject = new Subject();

        /**
         * @type {string|boolean}
         * @private
         */
        this._savePointValue = undefined;

        /**
         * @type {string|boolean}
         * @private
         */
        this._defaultValue = undefined;

        if (this.type === "checkbox" || this.type === "radio") {
            this._defaultValue = this.rootElement.defaultChecked;

            const formContainer = this.rootElement.closest("form") || document;
            const groupedInputs = [...formContainer.querySelectorAll(`${this.constructor.rootSelector}[name="${this.name}"]`)];

            this._initImmediateValidation();

            groupedInputs
                .filter(groupedInput => groupedInput !== rootElement)
                .forEach(groupedInput => {
                    groupedInput.addEventListener("blur", () => this._validate());
                    groupedInput.addEventListener("input", () => this._validate());
                });
        } else {
            this._defaultValue = this.rootElement.defaultValue;
        }

        if (this.type === "radio") {
            this._previousSelected = this.checked;
            this.rootElement.addEventListener("change", (event) => this._triggerChangeForGroupedRadios());
        }

        if (this.type === "checkbox") {
            const formContainer = this.rootElement.closest("form") || document;
            const groupedInputs = [...formContainer.querySelectorAll(`${this.constructor.rootSelector}[name="${this.name}"]`)];

            groupedInputs
                .filter(groupedInput => groupedInput !== rootElement)
                .forEach(groupedInput => {
                    if (this.hasAttribute("data-group-required") && !groupedInput.hasAttribute("data-group-required")) {
                        groupedInput.setAttribute("data-group-required", "");
                    }

                    if (this.hasAttribute("data-group-min") && !groupedInput.hasAttribute("data-group-min")) {
                        groupedInput.setAttribute("data-group-min", this.getAttribute("data-group-min"));
                    }

                    if (this.hasAttribute("data-group-max") && !groupedInput.hasAttribute("data-group-max")) {
                        groupedInput.setAttribute("data-group-max", this.getAttribute("data-group-max"));
                    }
                });
        }

        this.rootElement.addEventListener("change", (event) => this._changeSubject.notify(this, event));
        this.rootElement.addEventListener("focus", (event) => this._focusSubject.notify(this, event));
        this.rootElement.addEventListener("blur", (event) => this._blurSubject.notify(this, event));
    }

    /**
     * Triggers a change event on the previous selected radio.
     * @private
     */
    _triggerChangeForGroupedRadios() {
        if (!this.checked) {
            return;
        }

        const parentContainer = this.rootElement.closest("form") || document;
        const currentSelectedRadio = ComponentFactory
            .getInstances(parentContainer.querySelectorAll(`[name="${this.name}"]`))
            .find(groupedRadio => this !== groupedRadio && groupedRadio._previousSelected);

        this._previousSelected = true;

        if (currentSelectedRadio) {
            currentSelectedRadio._previousSelected = false;
            const event = new InputEvent("change", {
                view: window,
                bubbles: true,
                cancelable: true
            });
            currentSelectedRadio.rootElement.dispatchEvent(event);
        }
    }

    /**
     * Subject for change event.
     * @return {Subject}
     */
    get changeSubject() {
        return this._changeSubject;
    }

    /**
     * Subject for focus event.
     * @returns {Subject}
     */
    get focusSubject() {
        return this._focusSubject;
    }

    /**
     * Subject for blur event.
     * @returns {Subject}
     */
    get blurSubject() {
        return this._blurSubject;
    }

    /**
     * Returns the name of the input.
     * @return {string}
     */
    get name() {
        return this.rootElement.name;
    }

    /**
     * Returns the value of the input.
     * @return {string}
     */
    get value() {
        return this.rootElement.value;
    }

    /**
     * Sets the value of the input.
     * @param {string} value
     */
    set value(value) {
        if (value !== this.value) {
            this.rootElement.value = value;
            this._changeSubject.notify(this);
        }
    }

    /**
     * Returns the checked state of the input.
     * @return {boolean}
     */
    get checked() {
        return this.rootElement.checked;
    }

    /**
     * Sets the checked state of the input.
     * @param {boolean} checked
     */
    set checked(checked) {
        if (checked !== this.checked) {
            this.rootElement.checked = checked;
            this._changeSubject.notify(this);
        }
    }

    /**
     * Sets the disabled state of the input.
     * @param {boolean} disabled
     */
    set disabled(disabled) {
        this.rootElement.disabled = disabled;
    }

    /**
     * Returns the type of the input.
     * @return {string}
     */
    get type() {
        return this.rootElement.type;
    }

    /**
     * Sets the type of the input.
     * @param {string} type
     */
    set type(type) {
        this.rootElement.type = type;
    }

    /**
     * Returns the text of the label of the input.
     * @return {null|string}
     */
    get labelText() {
        if (this._labelElement) {
            return this._labelElement.innerText.trim();
        } else {
            return null;
        }
    }

    /**
     * Sets the text of the label of the input.
     * @param {string} labelText
     */
    set labelText(labelText) {
        if (this._labelElement) {
            this._labelElement.innerText = labelText;
        }
    }

    /**
     * Resets the input to its initial state.
     */
    reset() {
        if (this.type === "checkbox" || this.type === "radio") {
            this.checked = this._defaultValue;
        } else {
            this.value = this._defaultValue;
        }
    }

    /**
     * Saves the current value of the input to revert later to that value.
     */
    setSavepoint() {
        if (this.type === "checkbox" || this.type === "radio") {
            this._savePointValue = this.checked;
        } else {
            this._savePointValue = this.value;
        }
    }

    /**
     * Clears the savepoint if there is one.
     */
    clearSavepoint() {
        this._savePointValue = undefined;
    }

    /**
     * Reverts to a previous saved value. If no save point was defined, the method does nothing.
     */
    revertToSavepoint() {
        if (this._savePointValue !== undefined) {
            if (this.type === "checkbox" || this.type === "radio") {
                this.checked = this._savePointValue;
            } else {
                this.value = this._savePointValue;
            }

            this._savePointValue = undefined;
        }
    }

    /**
     * @override
     */
    get validity() {
        let validity = super.validity;

        if (
            this.type === "checkbox" &&
            (this.hasAttribute("data-group-required") ||
                this.hasAttribute("data-group-min") ||
                this.hasAttribute("data-group-max"))
        ) {
            const parentContainer = this.rootElement.closest("form") || document;
            const groupedCheckboxes = ComponentFactory.getInstances(
                parentContainer.querySelectorAll(`${this.constructor.rootSelector}[name="${this.name}"]`)
            );
            const checkedCheckboxes = groupedCheckboxes.filter(checkbox => checkbox.checked);

            if (this.hasAttribute("data-group-required")) {
                validity.violations.required = checkedCheckboxes.length === 0;
            }

            if (this.hasAttribute("data-group-min")) {
                const minCount = parseInt(this.getAttribute("data-group-min"));
                validity.violations.minValue = checkedCheckboxes.length < minCount;
            }

            if (this.hasAttribute("data-group-max")) {
                const maxCount = parseInt(this.getAttribute("data-group-max"));
                validity.violations.maxValue = checkedCheckboxes.length > maxCount;
            }

            validity.valid =
                validity.valid &&
                !validity.violations.required &&
                !validity.violations.minValue &&
                !validity.violations.maxValue;
        }

        return validity;
    }

    /**
     * @param event
     * @private
     */
    _preventSpaceToggle(event) {
        if (event.key === ' ' || event.keyCode === 32) {
            event.preventDefault();
        }
    }

    /**
     * Set inactive to avoid user interaction.
     * @param {boolean} inactive
     * */
    set inactive(inactive) {
        if (inactive) {
            this.rootElement.addEventListener('keydown', this._preventSpaceToggle);
            return
        }
        this.rootElement.removeEventListener('keydown', this._preventSpaceToggle);
    }
}

ComponentFactory.registerComponent(NucleusAInput);
