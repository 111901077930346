import "@nucleus/polyfill/src/_polyfill";
import { ComponentFactory, Component } from "@nucleus/core/src/_core";
import NucleusAInput from "@nucleus/a-input";

export default class NucleusMCheckbox extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "nuc-m-checkbox";
    }

    /**
     * Creates an instance of NucleusMCheckbox.
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._disabledClassName = "nuc-m-checkbox--disabled";

        this._count = this.rootElement.querySelector(".nuc-m-checkbox__label-count");

        /**
         * @type {NucleusAInput}
         * @private
         */
        this._input = ComponentFactory.getInstance(rootElement.querySelector(NucleusAInput.rootSelector));
    }

    /**
     * Set disabled state of the input.
     * @param disabled
     */
    set disabled(disabled) {
        this._input.disabled = disabled;

        if (disabled) {
            this.rootElement.classList.add(this._disabledClassName);
            return;
        }
        this.rootElement.classList.remove(this._disabledClassName);
    }

    /**
     * Set labelText of the input.
     * @param {string} labelText
     */
    set labelText(labelText) {
        this._input.labelText = labelText;
    }

    /**
     * Set count of the input label.
     * @param {string} labelText
     */
    set count(count) {
        this._count.innerText = `(${count})`;
    }

    /**
     * Set checked state of the input.
     * @param {boolean} checked
     */
    set checked(checked) {
        this._input.checked = checked;
    }

    /**
     * Returns the name of the input.
     * @return {string}
     */
    get name() {
        return this._input.name;
    }

    /**
     * Returns the value of the input.
     * @return {string}
     */
    get value() {
        return this._input.value;
    }

    /**
     * Set inactive to avoid user interaction.
     * @param {boolean} inactive
     * */
    set inactive(inactive) {
        if (inactive) {
            this.addClass(this._disabledClassName);
            this._input.inactive = true;
            return
        }
        this._input.removeClass(this._disabledClassName);
        this._input.inactive = false;
    }
}

ComponentFactory.registerComponent(NucleusMCheckbox);
