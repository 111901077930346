import "@nucleus/polyfill/src/_polyfill";
import { ComponentFactory, Component } from "@nucleus/core/src/_core";
import NucleusAButton from "@nucleus/a-button";
import LidlRecipesOOverlay from "@lidl-recipes/o-overlay";
import userService from "@lidl-recipes/global/lib/user-service";
import { getCookie, removeCookie, setCookie } from "@lidl-recipes/global/lib/cookies";

export default class LidlRecipesMFavoriteButton extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-m-favorite-button";
    }

    /**
     * Creates an instance of LidlRecipesMFavoriteButton.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._recipeId = rootElement.dataset.recipeId;
        this._isDeletedAfterRemove = "isDeletedAfterRemove" in rootElement.dataset;
        this._urlFavorites = rootElement.dataset.urlFavorites;
        this._recipeToBeAdded = getCookie("addRecipeToFavorites");
        this._gaFoodTypes = rootElement.dataset.gaFoodTypes;
        this._gaCourses = rootElement.dataset.gaCourses;
        this._gaCollections = rootElement.dataset.gaCollections;
        this._gaDiets = rootElement.dataset.gaDiets;
        this._gaRegions = rootElement.dataset.gaRegions;
        this._gaRecipeName = rootElement.dataset.gaRecipeName;
        this._gaType = rootElement.dataset.gaType;
        this._gaTime = rootElement.dataset.gaTime;
        this._gaQuantity = rootElement.dataset.gaQuantity;
        this._gaPosition = rootElement.dataset.gaPosition;
        this._gaDifficulty = rootElement.dataset.gaDifficulty;
        this._gaChef = rootElement.dataset.gaChef;

        /**
         * @type {LidlRecipesOOverlay}
         * @private
         */
        this._ctaModal = ComponentFactory.getInstance(document.querySelector("#sso-login-modal"));

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._addButton = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-m-favorite-button__add-button"));
        this._addButton.clickSubject.subscribe((element, event) => {
            event.preventDefault();
            if (!userService.isSignedIn) {
                this._handleSignedOutClick();
                this._pushGaClick();
                return;
            }
            this._addToFavorites();
            this._pushGaClick("On");
        });

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._removeButton = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-m-favorite-button__remove-button"));
        this._removeButton.clickSubject.subscribe((element, event) => {
            event.preventDefault();
            this._removeFromFavorites();
            this._pushGaClick("Off");
        });

        if (!userService.isSignedIn) return;
        this._loadFavoriteList();
    }

    /**
     * @param {("On"|"Off"|"Null")} state
     * @private
     */
    _pushGaClick(state = "Null") {
        if (!window.dataLayer) {
            console.warn("[nucleus] GTM variable dataLayer is not available.");
            window.dataLayer = [];
        }

        window.dataLayer.push({
            "event": "add_to_favorites",
            "origin": this._gaType === "teaser" ? "teaser_recipe" : "recipe_detail",
            "resulting_state": state || null,
            "recipe_id": this._recipeId || null,
            "time": this._gaTime || null,
            "chef": this._gaChef || null,
            "quantity": this._gaQuantity || null,
            "difficulty": this._gaDifficulty || null,
            "position": this._gaPosition || null,
            "recipe_name": this._gaRecipeName || null,
            "recipe_food_type": this._gaFoodTypes || null,
            "recipe_course": this._gaCourses || null,
            "recipe_collection": this._gaCollections || null,
            "recipe_diet": this._gaDiets || null,
            "recipe_region": this._gaRegions || null,
        });
    }

    /**
     * @type {null}
     * @private
     */
    static _favoritesListPromise = null;

    /**
     * @type {[]}
     * @private
     */
    static _favoritesList = [];

    /**
     * After sign-in, add recipe to favorites, if user has clicked before he was signed in.
     * @returns {Promise<void>}
     * @private
     */
    async _setRecipeToFavoriteAfterSignIn() {
        try {
            const response = await this._performFetch("PATCH", `${this._urlFavorites}/${userService.userId}/favoritesIds`, {
                op: "add",
                path: "/favoritesIds",
                value: this._recipeToBeAdded
            });
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            removeCookie("addRecipeToFavorites");
            this._pushGaClick("On");
        } catch (error) {
            console.error("An error occurred:", error);
        }
    }

    /**
     * @returns {NucleusAButton}
     */
    get removeButton() {
        return this._removeButton;
    }

    /**
     * If user is not signed in, store the intended recipe id in a cookie
     * to add it to favorites after sign in.
     * Then show sign-in cta overlay.
     * @private
     */
    _handleSignedOutClick() {
        setCookie("addRecipeToFavorites", this._recipeId, 1);
        this._ctaModal.open();
    }

    /**
     * Load Favorite list once and cache it.
     * @returns {Promise<void>}
     * @private
     */
    async _loadFavoriteList() {
        if (!LidlRecipesMFavoriteButton._favoritesListPromise) {
            LidlRecipesMFavoriteButton._favoritesListPromise = this._performFetch("GET", `${this._urlFavorites}/${userService.userId}/favoritesIds`)
                .then(response => {
                    if (!response.ok) {
                        if (response.status === 404) {
                            // User not found, so we have no favorites for this user
                            return [];
                        } else {
                            throw new Error(`HTTP error! status: ${response.status}`);
                        }
                    }
                    return response.json();
                })
                .then(async data => {
                    LidlRecipesMFavoriteButton._favoritesList = data;
                    if (this._recipeToBeAdded && userService.isSignedIn) {
                        await this._setRecipeToFavoriteAfterSignIn();
                        LidlRecipesMFavoriteButton._favoritesList.push(this._recipeToBeAdded);
                    }
                    return LidlRecipesMFavoriteButton._favoritesList;
                });
        }
        await (LidlRecipesMFavoriteButton._favoritesListPromise);
        this._isFavorite = LidlRecipesMFavoriteButton._favoritesList.includes(this._recipeId);
    }

    /**
     * @param isFavorite
     * @private
     */
    set _isFavorite(isFavorite) {
        if (isFavorite || this._isDeletedAfterRemove) {
            this._addButton.hide();
            this._removeButton.show();
            return;
        }
        this._addButton.show();
        this._removeButton.hide();
    }

    /**
     * @param {string} method
     * @param {string} url
     * @param {object} [body]
     * @returns {Promise<Response>}
     * @private
     */
    async _performFetch(method, url, body = null) {
        const options = {
            method,
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                "Authorization": `Bearer ${await userService.getAuthToken()}`
            }
        };
        if (body) {
            options.body = JSON.stringify(body);
        }
        return await fetch(url, options);
    }

    /**
     * @param recipeId
     * @returns {Promise<void>}
     * @private
     */
    async _addToFavorites() {
        this._isFavorite = true;
        this._addButton.disabled = true;
        this._removeButton.disabled = true;

        try {
            const response = await this._performFetch("PATCH", `${this._urlFavorites}/${userService.userId}/favoritesIds`, {
                op: "add",
                path: "/favoritesIds",
                value: this._recipeId
            });

            if (!response.ok) {
                this._isFavorite = false;
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            LidlRecipesMFavoriteButton._favoritesList.push(this._recipeId);
        } catch (error) {
            this._isFavorite = false;
            console.error("An error occurred:", error);
        } finally {
            this._addButton.disabled = false;
            this._removeButton.disabled = false;
        }
    }

    /**
     * @param recipeId
     * @returns {Promise<void>}
     * @private
     */
    async _removeFromFavorites() {
        this._isFavorite = false;
        this._addButton.disabled = true;
        this._removeButton.disabled = true;

        try {
            const response = await this._performFetch("DELETE", `${this._urlFavorites}/${userService.userId}/favoritesIds/${this._recipeId}`);

            if (!response.ok) {
                this._isFavorite = true;
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const index = LidlRecipesMFavoriteButton._favoritesList.indexOf(this._recipeId);
            if (index !== -1) {
                LidlRecipesMFavoriteButton._favoritesList.splice(index, 1);
            }
            if(this._isDeletedAfterRemove) {
                this.rootElement.closest(".nuc-a-flex-item").remove();
            }
        } catch (error) {
            this._isFavorite = true;
            console.error("An error occurred:", error);
        } finally {
            if(!this._isDeletedAfterRemove) {
                this._addButton.disabled = false;
                this._removeButton.disabled = false;
            }
        }
    }
}

ComponentFactory.registerComponent(LidlRecipesMFavoriteButton);
