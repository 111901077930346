import "@nucleus/polyfill/src/_polyfill";
import {Factory, Subject} from "@nucleus/core/src/_core";
import NucleusAButton from "@nucleus/a-button";

export default class NucleusMButton extends NucleusAButton {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "nuc-m-button";
    }

    /**
     * Creates an instance of NucleusMButton.
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._buttonText = this.querySelector(".nuc-m-button__text");

        this._addModifiers({
            root: {
                disabled: "nuc-m-button--disabled"
            }
        });
    }

    /**
     * Get button text.
     * @returns {*}
     */
    get text() {
        return this._buttonText.innerText;
    }

    /**
     * Set button text.
     * @param text
     */
    set text(text) {
        this._buttonText.innerText = text;
    }

    /**
     * @inheritDoc
     */
    set disabled(disabled) {
        super.disabled = disabled;

        if (disabled) {
            this.rootElement.classList.add(this._modifiers.root.disabled);
        } else {
            this.rootElement.classList.remove(this._modifiers.root.disabled);
        }
    }
}

Factory.registerComponent(NucleusMButton);
