import "@nucleus/polyfill/src/_polyfill";
import { Component, Factory } from "@nucleus/core/src/_core";
import { FOCUSABLE_ELEMENTS, KEYCODES } from "@nucleus/core/src/_constants";

export default class LidlRecipesOOverlay extends Component {
    static get rootClassName() {
        return "lirc-o-overlay";
    }

    /**
     * Creates an instance of LidlRecipesOOverlay.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._dialog = this.rootElement.querySelector(".lirc-o-overlay__dialog");
        this._documentBody = document.getElementsByTagName("body")[0];
        this._documentBodyOverflowInital = "";
        this._isOpen = false;
        this._boundKeyHandler = this._handleKeyEvents.bind(this);
        this._boundResizeHandler = this._setVhCssVariable.bind(this);

        if (this.hasAttribute("data-overlay-auto-triggered")) {
            this.rootElement.removeAttribute("data-overlay-auto-triggered");
            this.open();
        }

        /**
         * @type {NucleusMButton}
         * @private
         */
        this._closeButton = Factory.createComponent(
            this.rootElement.querySelector(".lirc-o-overlay__close-button")
        );
        this._closeButton.clickSubject.subscribe(() => this.close());

        /**
         * @type {NucleusMButton}
         * @private
         */
        this._closeButtonBottom = Factory.createComponent(
            this.rootElement.querySelector(".lirc-o-overlay__close-button-bottom")
        );
        if (this._closeButtonBottom) {
            this._closeButtonBottom.clickSubject.subscribe(() => this.close());
        }

        this._dialog.addEventListener("click", event => {
            if (event.target === this._dialog) {
                this.close();
            }
        });
    }

    /**
     * Open the overlay.
     * @public
     */
    open() {
        this.show();
        this._setVhCssVariable();
        this._documentBodyOverflowInital = this._documentBody.style.overflow; // remember this to restore later
        this._documentBody.style.overflow = "hidden";

        document.addEventListener("keydown", this._boundKeyHandler);
        window.addEventListener("resize", this._boundResizeHandler);

        const focusableNodes = this._getFocusableNodes();
        if (focusableNodes.length) {
            focusableNodes[0].focus();
        }
    }

    /**
     * Close the overlay.
     * @public
     */
    close() {
        this.hide();
        this._documentBody.style.overflow = this._documentBodyOverflowInital;

        document.removeEventListener("keydown", this._boundKeyHandler);
        window.removeEventListener("resize", this._boundResizeHandler);

        if (this._triggerElement) {
            this._triggerElement.focus();
        }
    }

    /**
     * Set trigger element.
     * @param triggerElement
     */
    set triggerElement(triggerElement) {
        this._triggerElement = triggerElement;
    }

    /**
     * Handle key events while the overlay is open.
     * @param {Event} [event] original event
     * @private
     */
    _handleKeyEvents(event) {
        const key = event.code || event.keyCode;
        switch (key) {
            case KEYCODES.ESC:
            case "Escape":
            case "Esc":
                this.close();
                break;
            case KEYCODES.TAB:
            case "Tab":
                this._trapFocus(event);
                break;
            default:
                break;
        }
    }

    /**
     * Trap the focus inside the overlay so that user cannot move into the background page by tabbing.
     * @param {KeyboardEvent} [event] original event
     * @private
     */
    _trapFocus(event) {
        const focusableNodes = this._getFocusableNodes();
        if (!this.rootElement.contains(document.activeElement)) {
            focusableNodes[0].focus();
        } else {
            const focusedItemIndex = focusableNodes.indexOf(document.activeElement);
            if (event.shiftKey && focusedItemIndex === 0) {
                focusableNodes[focusableNodes.length - 1].focus();
                event.preventDefault();
            }
            if (!event.shiftKey && focusedItemIndex === focusableNodes.length - 1) {
                focusableNodes[0].focus();
                event.preventDefault();
            }
        }
    }

    /**
     * Find and return all focusable elements inside the dialog as array.
     * @return {Element[]}
     * @private
     */
    _getFocusableNodes() {
        const nodes = this.rootElement.querySelectorAll(FOCUSABLE_ELEMENTS);
        return Array.from(nodes);
    }

    /**
     * Set CSS variable vh to correspond to proper viewport height
     * Overlay should fill entire screen -> for iOS we need to fix display when browser bars are displayed
     * see https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
     * @private
     */
    _setVhCssVariable() {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty("--vh", vh + "px");
    }
}

Factory.registerComponent(LidlRecipesOOverlay);
