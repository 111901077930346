/**
 * Identifies the type of the current webpage.
 *
 * @returns {string} A string indicating the type of page:
 *                   - 'home' for the homepage,
 *                   - 'favorites' for the favorites list page,
 *                   - 'allrecipes' for the all recipes search page,
 *                   - 'recipedetail' for the recipe detail page,
 *                   - 'landing' for any other page.
 */
export function identifyPage() {
    // Check if on homepage
    if (window.location.pathname === '/') {
        return 'home';
    }

    // Check for favorites list
    if (document.querySelector('.lirc-o-favorite-list')) {
        return 'favorites';
    }

    // Check for search page
    if (document.querySelector('.lirc-o-recipe-search__filter-panel')) {
        return 'allrecipes';
    }

    // Check for detail page
    if (document.querySelector('.lirc-recipe-detail')) {
        return 'recipedetail';
    }

    // Default to landing page
    return 'landing';
}
