/**
* Gets the value of cookie
* 
* @function getCookie
* @param {string} name Name of the cookie to get
* @returns {null|string}
*/
export function getCookie(name) {
 const value = `; ${document.cookie}`;
 const parts = value.split(`; ${name}=`);
 if (parts.length === 2) return parts.pop().split(";").shift();
 return null;
}

/**
* Sets a cookie
* 
* @function setCookie
* @param {string} name Cookie name
* @param {string} value Cookie value
* @param {number} days Cookie duration, expressed in days
*/
export function setCookie(name, value, days) {
   let expires = "";
   if (days) {
       const date = new Date();
       date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
       expires = "; expires=" + date.toUTCString();
   }
   document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

/**
* Removes a cookie
* 
* @function removeCookie
* @param {string} name Name of cookie to remove
*/
export function removeCookie(name) {
   document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}