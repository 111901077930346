import CoreExtension from "./core-extension.js";

/**
 * Keep this extension of nunjucks in sync with the Twig version of it in Nucleus\CoreBundle\Twig\SchemaExtension.
 */
export default class SchemaExtension {

    constructor(nunjucksWrapper) {
        this._nunjucksWrapper = nunjucksWrapper;

        nunjucksWrapper.environment.addFilter("validate_data", (data, component) => this.validateData(data, component));
        nunjucksWrapper.environment.addFilter("build_base_component", (data, component) => this.buildBaseComponent(data, component));
        nunjucksWrapper.environment.addGlobal("get_manifest", () => nunjucksWrapper.getNucleusManifest());
        nunjucksWrapper.environment.addGlobal("get_settings", () => nunjucksWrapper.getNucleusManifest().settings);
    }

    static currentComponentScope = "";

    /**
     * Returns the current component.
     * @returns {String}
     */
    static getCurrentComponentScope() {
        return this.currentComponentScope;
    }

    /**
     * Sets the current component.
     * @param {String} value
     */
    static setCurrentComponentScope(value) {
        this.currentComponentScope = value;
    }

    validateData(data, component, deepValidate) {
        data["_validation"] = {
            hasErrors: false,
            hasWarnings: false,
            errors: [],
            warnings: []
        };
        let moduleName = null;

        if (data["component"]) {
            moduleName = data["component"];
        } else if (data["module"]) {
            moduleName = data["module"];
        } else {
            this._setValidationError(data, "Invalid object for module. Either component or module must be set.", "module");
            return data;
        }

        if (!component) {
            component = moduleName;
        } else if (component !== moduleName) {
            this._setValidationError(data, `The component ID provided for '${component}' does not match: '${moduleName}'.`, "module");
            return data;
        }

        const manifest = this._nunjucksWrapper.getNucleusManifest();

        if (!manifest["modules"]["@" + component]) {
            this._setValidationError(data, `Unknown module '${component}'.`, "module");
            return data;
        }

        const schema = manifest["modules"]["@" + component]["schema"];

        for (let key in schema["properties"]) {
            let propertyName = key;
            let propertyOptions = schema["properties"][key];

            if (!data[propertyName]) {
                if (schema["required"].includes(propertyName)) {
                    this._setValidationError(data, `The required property '${propertyName}' is missing in the data of '${component}'.`, propertyName);
                } else if (propertyOptions["default"]) {
                    data[propertyName] = propertyOptions["default"];
                } else {
                    data[propertyName] = null;
                }
            } else {
                if (propertyOptions["deprecated"]) {
                    this._setValidationWarning(data, `Deprecated property '${propertyName}' is used in the data of '${component}'.`, propertyName, data[propertyName]);
                }

                if (propertyOptions["type"] === "string" && propertyOptions["enum"]) {
                    if (typeof data[propertyName] === "object" && propertyOptions["isResponsive"] === true) {
                        for (let key in data[propertyName]) {
                            let viewport = key;
                            let propertyValue = data[propertyName][key];

                            if (!propertyOptions["enum"].includes(propertyValue)) {
                                if (propertyOptions["default"]) {
                                    data[propertyName][viewport] = propertyOptions["default"];
                                } else {
                                    data[propertyName] = null;
                                }
                            }
                        }
                    } else if (!propertyOptions["enum"].includes(data[propertyName])) {
                        this._setValidationWarning(data, `Invalid value for property '${propertyName}' in the data of '${component}'.`, propertyName, data[propertyName]);

                        if (propertyOptions["default"]) {
                            data[propertyName] = propertyOptions["default"];
                        } else {
                            data[propertyName] = null;
                        }
                    }
                } else if (deepValidate && propertyOptions["type"] === "object" && propertyName.startsWith("object")) {
                    data[propertyName] = this.validateData(data[propertyName], null, true);
                    this._hoistChildValidationResult(data, data[propertyName], propertyName);
                }
            }
        }

        const diff = (a, b) => a.filter(v => !b.includes(v));
        const unknownProperties = diff(Object.keys(data), [
            ...Object.keys(schema["properties"]),
            ...this._getInheritedProperties(schema),
            "_validation"
        ]);
        for (let unknownProperty of unknownProperties) {
            this._setValidationWarning(data, `Unknown property '${unknownProperty}' in the data of '${component}'.`, unknownProperty, data[unknownProperty]);
        }

        if (data["extensions"]) {
            for (let i = 0; i < data["extensions"].length; i++) {
                data["extensions"][i] = this.validateData(data["extensions"][i], null, true);
                this._hoistChildValidationResult(data, data["extensions"][i], `extensions[${i}]`);
            }
        }

        if (data._validation.hasErrors || data._validation.hasWarnings) {
            this._logValidationResults(data);
        }

        SchemaExtension.setCurrentComponentScope(moduleName);

        return data;
    }

    _setValidationError(data, message, property) {
        data["_validation"]["hasErrors"] = true;
        data["_validation"]["errors"].push({
            message: message,
            propertyPath: [property]
        });
    }

    _setValidationWarning(data, message, property, value = null) {
        data["_validation"]["hasWarnings"] = true;
        data["_validation"]["warnings"].push({
            message: message,
            propertyPath: [property],
            value: value
        });
    }

    _hoistChildValidationResult(data, childData, path) {
        if (childData["_validation"]["hasErrors"]) {
            data["_validation"]["hasErrors"] = true;
            for (const error of childData["_validation"]["errors"]) {
                error["propertyPath"].unshift(path);
                data["_validation"]["errors"].push(error);
            }
        }

        if (childData["_validation"]["hasWarnings"]) {
            data["_validation"]["hasWarnings"] = true;
            for (const warning of childData["_validation"]["warnings"]) {
                array_unshift(warning["propertyPath"], path);
                data["_validation"]["warnings"].push(warning);
            }
        }
    }

    /**
     * @param data
     * @private
     */
    _logValidationResults(data) {
        if (data._validation.hasErrors) {
            console.error(`[nucleus] Validation Errors in "${data.component}"`);
        } else {
            console.warn(`[nucleus] Validation Warnings in "${data.component}"`);
        }

        console.groupCollapsed("   Validation Details:");

        for (let error of data._validation.errors) {
            console.error(error.message, error);
        }

        for (let warning of data._validation.warnings) {
            console.warn(warning.message, warning);
        }

        console.log("Component Data:");
        console.log(data);
        console.log("Stack Trace:");
        console.trace();
        console.groupEnd();
    }

    _getInheritedProperties(schema) {
        let properties = [];

        while (schema["extends"]) {
            let baseSchema = this._nunjucksWrapper.getNucleusManifest()["modules"][`@${schema["extends"]["baseComponent"]}`]["schema"];
            const diff = (a, b) => a.filter(v => !b.includes(v));
            properties = properties.concat(diff(
                    Object.keys(baseSchema["properties"]),
                    schema["extends"]["overriddenProperties"]
            ));
            schema = baseSchema;
        }

        return properties;
    }

    /**
     *
     * @param {Object} data
     * @param {Object} additionalData
     * @return {Object}
     */
    buildBaseComponent(data, additionalData = null) {
        let module;

        if (data["module"]) {
            module = data["module"];
        } else {
            module = data["component"];
        }

        const schema = this._nunjucksWrapper.getNucleusManifest()["modules"][`@${module}`]["schema"];
        Object.keys(schema["properties"]).forEach(key => delete data[key]);
        data["component"] = schema["extends"]["baseComponent"];

        if (additionalData) {
            data = CoreExtension.mergeData(data, additionalData);
        }

        return data;
    }
}
