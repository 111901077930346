/**
 * Extension to make Nunjucks compatible with Twig by adding missing extensions
 */
export default class TwigCompatExtension {

    constructor(nunjucksWrapper) {
        this._nunjucksWrapper = nunjucksWrapper;

        nunjucksWrapper.environment.addTest("empty", TwigCompatExtension.empty);
        nunjucksWrapper.environment.addTest("iterable", TwigCompatExtension.iterable);
        nunjucksWrapper.environment.addFilter("json_encode", TwigCompatExtension.jsonEncode);
        nunjucksWrapper.environment.addFilter("merge", TwigCompatExtension.merge);
        nunjucksWrapper.environment.addFilter("raw", nunjucksWrapper.environment.getFilter("safe"));
        nunjucksWrapper.environment.addFilter("split", TwigCompatExtension.split);
        nunjucksWrapper.environment.addFilter("url_encode", nunjucksWrapper.environment.getFilter("urlencode"));
        nunjucksWrapper.environment.addFilter("striptags", TwigCompatExtension.striptags);
        nunjucksWrapper.environment.addFilter("default", TwigCompatExtension.default);
        nunjucksWrapper.environment.addGlobal("random", TwigCompatExtension.random);
        nunjucksWrapper.environment.addGlobal("range", TwigCompatExtension.range);
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/tests/empty.html Twig Documentation}
     */
    static empty(value) {
        return (!value ||
            (value instanceof Array && value.length === 0) ||
            (value instanceof Object && Object.keys(value).length === 0)
        );
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/tests/iterable.html Twig Documentation}
     */
    static iterable(value) {
        return typeof value === "object";
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/filters/merge.html Twig Documentation}
     */
    static merge(value1, value2) {
        if (value1 instanceof Array && value2 instanceof Array) {
            return value1.concat(value2);
        } else if (value1 instanceof Object && value2 instanceof Object) {
            return Object.assign(value1, value2);
        } else {
            throw new Error("Both values must be either a array or object");
        }
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/filters/json_encode.html Twig Documentation}
     */
    static jsonEncode(value) {
        return JSON.stringify(value);
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/functions/random.html Twig Documentation}
     */
    static random(values, max) {
        if (values && !max) {
            switch (typeof values) {
                case "number":
                    return Math.round(Math.random() * values);
                case "string":
                    return values.charAt(Math.round(Math.random() * (values.length - 1)));
                case "object":
                    return values[Math.round(Math.random() * (values.length - 1))];
            }
        } else if (values && max) {
            return Math.round(Math.random() * (max - values)) + values;
        } else {
            return Math.round(Math.random() * 2147483647);
        }
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/filters/split.html Twig Documentation}
     */
    static split(value, delimiter) {
        return value.split(delimiter);
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/filters/striptags.html Twig Documentation}
     * @see {@link https://locutus.io/php/strings/strip_tags/ Community JS Source Code }
     */
    static striptags(value, allowed) {
        const tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi;
        const comments = /<!--[\s\S]*?-->/gi;
        allowed = (
            ((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) ||
            []
        ).join("");
        let before = null;

        while (before !== value) {
            before = value;
            value = value.replace(comments, "").replace(tags, ($0, $1) => {
                return allowed.includes("<" + $1.toLowerCase() + ">") ? $0 : "";
            });
        }

        return value;
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/filters/default.html Twig Documentation}
     */
    static default(value, def) {
        return value || def;
    }

    /**
     * @see {@link https://twig.symfony.com/doc/2.x/functions/range.html Twig Documentation}
     */
    static range(start, stop, step) {
        if (!step) {
            step = 1;
        }

        var values = [];

        if (step > 0) {
            for (let i = start; i <= stop; i += step) {
                values.push(i);
            }
        } else {
            for (let i = start; i >= stop; i += step) {
                values.push(i);
            }
        }

        return values;
    }
}
