import "@nucleus/polyfill/src/_polyfill";
import { Component, ComponentFactory } from "@nucleus/core/src/_core";
import NucleusAButton from "@nucleus/a-button";

export default class LidlRecipesMFilterChip extends Component {

  /**
   * @inheritDoc
   */
  static get rootClassName() {
    return "lirc-m-filter-chip";
  }

  /**
   * Creates an instance of LidlRecipesMFilterChip.
   *
   * @param {Element} rootElement
   */
  constructor(rootElement) {
    super(rootElement);

    /**
     * @type {NucleusAButton}
     * @private
     */
    this._removeButton = ComponentFactory.getInstance(this.rootElement.querySelector(NucleusAButton.rootSelector));
  }

  /**
   * Returns the filter id.
   * @returns {string}
   */
  get filterId() {
    return this._removeButton.dataset.filterId;
  }

  /**
   * Returns instance of remove button.
   * @returns {NucleusAButton}
   */
  get removeButton() {
    return this._removeButton;
  }
}

ComponentFactory.registerComponent(LidlRecipesMFilterChip);
