import "@nucleus/polyfill/src/_polyfill";
import { ComponentFactory, Factory } from "@nucleus/core/src/_core";
import LidlRecipesMSuggestField from "@lidl-recipes/m-suggest-field";
import { NunjucksWebWrapper } from "@nucleus-tools/nunjucks-web";

export default class LidlRecipesMSearchFilterAutosuggestionField extends LidlRecipesMSuggestField {
    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-m-search-filter-autosuggestion-field";
    }

    /**
     * Creates an instance of LidlRecipesMSearchFilterAutosuggestionField.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);
        this._fullList = JSON.parse(this.rootElement.dataset.fullList);
        this._selectType = this.rootElement.dataset.selectType;
        this._filterName = this.rootElement.dataset.filterName;
        this._availableFilters = JSON.parse(this.rootElement.dataset.availableFilters);
        this._suggestInput = this.rootElement.querySelector(".lirc-m-suggest-field__input");
        this._listBox = this.rootElement.querySelector(".lirc-m-suggest-field__listbox");
        this._suggestFieldIcon = this.rootElement.querySelector(".lirc-m-suggest-field__combobox .lirc-m-suggest-field__icon");
        this._minCharLength = this.rootElement.dataset.minCharLength || 1;
        this._responseResult = null;
    }

    _autocomplete(val) {
        let _result = [];

        // Show the whole list initially, when the input field is empty
        if(false === val || val.length < this._minCharLength){
            return this._fullList;
        }

        for (let i = 0; i < this._fullList.length; i++) {
            if (this._fullList[i].name.toLowerCase().includes(val.toLowerCase())) {
                _result.push(this._fullList[i]);
            }
        }

        return _result;
    }

    /**
     * Determines if the current checkbox should be checked
     *
     * @param {String} id
     * @return {Boolean}
     * @private
     */
    _setCheckboxChecked(id) {
        if( this.dataset !== undefined && this.dataset.selectedItems !== undefined && this.dataset.selectedItems.length > 0){
            const _selectedItems = this.dataset.selectedItems.split(',');
            return _selectedItems.indexOf(id) > -1;
        }
        return false;
    }

    async _searchFn(searchString) {
        let results = [];

        try {
            let responseResults = this._autocomplete(searchString);
            if (responseResults.length > 0) {
                responseResults.forEach(itemData => {
                    let _isDisabled = false;

                    if ('difficulties' === this._filterName) {
                        _isDisabled = this._availableFilters[this._filterName].find(e => e.id.toString() === itemData.id.toString()) == undefined;
                    } else {
                        _isDisabled = this._availableFilters[this._filterName].find(e => e.id.id.toString() === itemData.id.toString()) == undefined;
                    }

                    results.push({
                        component: "lidl-recipes/a-suggest-field-item",
                        type: this._selectType,
                        textLabel: this._hasHighlightedChars
                            ? this._highlightString(
                                itemData.name,
                                searchString
                            )
                            : itemData.name,
                        isChecked: _isDisabled ? false : this._setCheckboxChecked(itemData.id),
                        value: itemData.id,
                        htmlAttributes: {
                            "data-id": itemData.id,
                            "data-filter-name": this._filterName,
                            "data-label": itemData.name,
                            classList: [
                                this.rootClassName + "__suggest-field-item",
                                _isDisabled ? this.rootClassName + "__suggest-field-item--disabled" : "",
                            ]
                        },
                        name: this.rootElement.dataset.inputName
                    });
                });
            } else {
                results.push({
                    component: "lidl-recipes/a-suggest-field-item",
                    type: "no-results",
                    textLabel: "no results found",
                    htmlAttributes: {
                        class: this.rootClassName + "__suggest-field-item-no-results"
                    }
                });
            }
        } catch (err) {
            console.error("Suggest fetch failed", err);
        }

        return results;
    }
}

Factory.registerComponent(LidlRecipesMSearchFilterAutosuggestionField);
