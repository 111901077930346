import "@nucleus/polyfill/src/_polyfill";
import { Component, Factory } from "@nucleus/core/src/_core";

export default class LidlRecipesOTeaserListBlock extends Component {
    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-teaser-list-block";
    }

    /**
     * Creates an instance of LidlRecipesOTeaserListBlock.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);
    }
}

Factory.registerComponent(LidlRecipesOTeaserListBlock);
