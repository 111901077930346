import "@nucleus/polyfill/src/_polyfill";
import { Component, ComponentFactory, Factory } from "@nucleus/core/src/_core";
import { KEYCODES } from "@nucleus/core/src/_constants";
import EventUtils from "@nucleus/core/src/_eventUtils";
import LidlRecipessASuggestFieldItem from "@lidl-recipes/a-suggest-field-item";
import { NunjucksWebWrapper } from "@nucleus-tools/nunjucks-web";
import NucleusAFormField from "@nucleus/a-form-field";

export default class LidlRecipesMSuggestField extends NucleusAFormField {

    static get rootClassName() {
        return "lirc-m-suggest-field";
    }

    /**
     * Creates an instance of LidlRecipesMSuggestField.
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._inputFieldSelector = ".lirc-m-suggest-field__input";

        /**
         * @type {NucleusAInput}
         * @protected
         */
        this._input = Factory.createComponent(this.querySelector(this._inputFieldSelector));

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._clearButton = Factory.createComponent(this.querySelector(".lirc-m-suggest-field__clear-button"));

        /**
         * @type {Component}
         * @private
         */
        this._toggleButton = Factory.createComponent(this.querySelector(".lirc-m-suggest-field__list-toggle-button"));

        this._toggleButtonIcon = this._toggleButton.rootElement.querySelector(".lirc-m-suggest-field__list-toggle-button-icon");

        /**
         * @type {Component}
         * @private
         */
        this._listbox = Factory.createComponent(this.querySelector(".lirc-m-suggest-field__listbox"));

        /**
         * @type {Element}
         * @private
         */
        this._combobox = this.querySelector(".lirc-m-suggest-field__combobox");

        /**
         * @type {boolean}
         * @private
         */
        this._hasAutoSelect = typeof rootElement.dataset.hasAutoSelect !== "undefined";

        /**
         * @type {boolean}
         * @protected
         */
        this._hasHighlightedChars = typeof rootElement.dataset.hasHighlightedChars !== "undefined";

        /**
         * @type {*|number}
         * @private
         */
        this._minCharLength = rootElement.dataset.minCharLength || 1;

        /**
         * @type {number}
         * @private
         */
        this._activeIndex = -1;

        /**
         * @type {number}
         * @private
         */
        this._resultsCount = 0;

        /**
         * @type {boolean}
         * @private
         */
        this._isCancelled = false;

        this._updateUrl = this.dataset.updateUrl;

        document.body.addEventListener("click", event => {
            this._checkHide(event);
        });

        if( this._toggleButton ) {
            this._toggleButton.clickSubject.subscribe( () => {
                if( this._toggleButtonIcon.classList.contains("lirc-m-suggest-field__list-toggle-button-icon--down") ){
                    this._changeIconOrientation("up");
                    this._isCancelled = false;
                    this._updateResults();
                } else {
                    this._changeIconOrientation("down");
                    this._isCancelled = true;
                    this._hideListbox();
                }
            });
        }

        this._input.rootElement.addEventListener("keydown", event => { // with keyup the TAB key is not recognized
            this._checkKey(event);
            this._setActiveItem(event);
        });
        if (this._clearButton) {
            this._clearButton.clickSubject.subscribe(() => {
                this._clearInput();
            });
        }
        this._input.focusSubject.subscribe(() => {
            this._isCancelled = false;
            this._updateResults();
        });
        this._input.blurSubject.subscribe(() => {
            this._isCancelled = true;
        });
    }

    /**
     * Change icon orientation
     * @param {String} orientation
     * @private
     */
    _changeIconOrientation(orientation) {
        if(orientation === "up"){
            this._toggleButtonIcon.classList.add("lirc-m-suggest-field__list-toggle-button-icon--up");
            this._toggleButtonIcon.classList.remove("lirc-m-suggest-field__list-toggle-button-icon--down");
        } else {
            this._toggleButtonIcon.classList.add("lirc-m-suggest-field__list-toggle-button-icon--down");
            this._toggleButtonIcon.classList.remove("lirc-m-suggest-field__list-toggle-button-icon--up");
        }
    }

    /**
     * Search function, used to pass results to the suggest-field component.
     * Should be overwritten by extending components, to pass search results.
     * @return {Promise<Array<Object>>}
     * @abstract
     * @protected
     */
    async _searchFn() {
        throw new Error("You have to implement the method _searchFn!");
    }

    /**
     * Getter for the input field.
     * @return {NucleusAInput}
     */
    get input() {
        return this._input;
    }

    /**
     * Check pressed key.
     * @param {Object} event
     * @private
     */
    _checkKey = EventUtils.debounce((event) => {
        let key = event.which || event.keyCode;

        this._refreshClearButtonStates();

        switch (key) {
            case KEYCODES.UP:
            case KEYCODES.DOWN:
            case KEYCODES.ESC:
            case KEYCODES.RETURN:
            case KEYCODES.TAB:
                event.preventDefault();
                this._isCancelled = true;
                return;
            default:
                this._updateResults();
        }
    }, 250);

    /**
     * Clear input field.
     * @protected
     */
    _clearInput() {
        this._input.value = "";
        this._input.focus();

        this._refreshClearButtonStates();
    }

    /**
     * Refresh clear button state in input field.
     * @protected
     */
    _refreshClearButtonStates() {
        if (!this._clearButton) {
            return;
        }

        if (this._input.value.length > 0) {
            this._clearButton.show();

            if (this._toggleButton) {
                this._toggleButton.hide();
            }
        } else {
            this._clearButton.hide();

            if (this._toggleButton) {
                this._toggleButton.show();
            }
        }
    }

    /**
     * Update results.
     * @returns {Promise<void>}
     * @private
     */
    async _updateResults() {
        this._isCancelled = false;
        const searchString = this._input.value;

        this._hideListbox();
        this._changeIconOrientation("down");

        let results = await this._searchFn(searchString);

        if (searchString !== this._input.value || this._isCancelled) {
            // this is no longer the latest request/response
            return;
        }

        if (results.length) {
            for (let item of results) {
                let renderedSuggestFieldItem = await NunjucksWebWrapper.renderComponent(item);
                this._listbox.rootElement.insertAdjacentHTML("beforeend", renderedSuggestFieldItem);
            }

            let items = Factory.createComponents(this._listbox.querySelectorAll(LidlRecipessASuggestFieldItem.rootSelector));
            items.forEach((item) => {
                if( false === item.rootElement.classList.contains("lirc-m-search-filter-autosuggestion-field__suggest-field-item-no-results")
                    && false === item.rootElement.classList.contains("lirc-m-search-filter-autosuggestion-field__suggest-field-item--disabled")
                ) {
                    this.rootElement.addEventListener('keydown', (e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            e.stopPropagation();
                            if (document.activeElement.dataset.currentItemId === item.querySelector("input").value) {
                                this._updateElements(item);
                            }
                        }
                    });
                    item.rootElement.addEventListener("click", (e) => {
                        e.preventDefault();
                        this._updateElements(item);
                    });
                } else {
                    item.rootElement.addEventListener("click", (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                    });
                }
            });

            if (this._hasAutoSelect) {
                this._getItemAt(0).focus();
                this._activeIndex = 0;
            }

            this._listbox.show();
            this._changeIconOrientation("up");

            this._combobox.setAttribute("aria-expanded", "true");
            this._resultsCount = results.length;
        }
    }

    _updateElements(item) {
        this._updateInputField(item);
        let _filterName = item.rootElement.dataset.filterName;
        let _filterValue = item.rootElement.dataset.id;
        if (item.querySelector("input").checked) {
            this._updateFilterValueInUrl(false, _filterName, _filterValue);
        } else {
            this._updateFilterValueInUrl(true, _filterName, _filterValue);
        }
        this._updateFiltersAndSearchResults();
    }

    /**
     * Set the active item.
     * @param {Object} event
     * @private
     */
    _setActiveItem(event) {
        let key = event.which || event.keyCode;
        let activeIndex = this._activeIndex;

        if (key === KEYCODES.ESC) {
            this._hideListbox();
            this._changeIconOrientation("down");

            setTimeout(() => {
                // On Firefox, input does not get cleared here unless wrapped in a setTimeout
                this._input.value = "";
            }, 1);

            return;
        }

        let prevActive = this._getItemAt(activeIndex);
        let activeItem;

        switch (key) {
            case KEYCODES.UP:
                if (activeIndex <= 0) {
                    activeIndex = this._resultsCount - 1;
                } else {
                    activeIndex--;
                }
                break;
            case KEYCODES.DOWN:
                if (
                    activeIndex === -1 ||
                    activeIndex >= this._resultsCount - 1
                ) {
                    activeIndex = 0;
                } else {
                    activeIndex++;
                }
                break;
            case KEYCODES.RETURN:
            case KEYCODES.TAB:
                this._checkSelection();
                this._hideListbox();
                this._changeIconOrientation("down");
                return;
            default:
                return;
        }

        event.preventDefault();
        activeItem = this._getItemAt(activeIndex);
        this._activeIndex = activeIndex;

        if (prevActive) {
            prevActive.blur();
        }

        if (activeItem) {
            this._input.rootElement.setAttribute("aria-activedescendant", activeItem.rootId);
            activeItem.focus();
        } else {
            this._input.rootElement.setAttribute("aria-activedescendant", "");
        }
    }

    /**
     * Function returns a specific suggest field item.
     * @param {Integer} index
     * @return {LidlRecipessASuggestFieldItem}
     * @private
     */
    _getItemAt(index) {
        if (index === -1) {
            return null;
        }

        let item = Array.from(this._listbox.rootElement.childNodes).filter(node => node.nodeType === Node.ELEMENT_NODE)[index];
        return Factory.createComponent(item);
    }

    /**
     * Get the selected item and fill the input field.
     * @param {LidlRecipessASuggestFieldItem} item
     * @protected
     */
    _updateInputField(item) {
        this.rootElement.querySelector(this._inputFieldSelector).setAttribute("data-current-item-id", item.dataset.id);
        if( this._input.value === item.textLabel ){
            //Bugfix if item/badge (ref: o-filter-badge-container.js) was removed and is directly selected again
            this._input.value = "";
        }
        this._input.value = item.textLabel;
        this._hideListbox();
        this._changeIconOrientation("down");
    }

    /**
     * If clicked outside of suggest field or listbox, hide it.
     * @param {Object} event
     * @private
     */
    _checkHide(event) {
        if (event.target === this._input.rootElement || this._combobox.contains(event.target)) {
            return;
        }

        this._hideListbox();
        this._changeIconOrientation("down");
    }

    /**
     * Hide listbox.
     * @private
     */
    _hideListbox() {
        this._activeIndex = -1;
        this._resultsCount = 0;
        this._listbox.rootElement.innerHTML = "";
        this._listbox.hide();
        this._combobox.setAttribute("aria-expanded", "false");
        this._input.rootElement.setAttribute("aria-activedescendant", "");
    }

    /**
     * Check selection.
     * @private
     */
    _checkSelection() {
        if (this._activeIndex < 0) {
            return;
        }

        let activeItem = this._getItemAt(this._activeIndex);
        this._updateInputField(activeItem);
    }

    /**
     * Highlight entered text in result items.
     * @param {Object} result
     * @param {String} string
     * @returns {*}
     * @protected
     */
    _highlightString(result, string) {
        return result.replace(RegExp(string.replace(/[-\\^$*+?.()|[\]{}]/g, "\\$&"), "gi"), "<mark>$&</mark>");
    }

    /**
     * Add filter value to url
     *
     * @param {boolean} hasAddMode
     * @param {string} filterName
     * @param {string} filterValue
     * @private
     */
    _updateFilterValueInUrl(hasAddMode, filterName, filterValue) {
        const url = new URL(window.location);
        const filtersParameter = "filters[" + filterName + "]";
        let _filterValues = url.searchParams.get(filtersParameter);

        if (url.searchParams.has("page")) {
            url.searchParams.delete("page");
        }

        if (hasAddMode) {
            if (url.searchParams.has(filtersParameter)) {
                url.searchParams.set(filtersParameter, _filterValues + "," + filterValue);
            } else {
                url.searchParams.set(filtersParameter, filterValue);
            }
        } else {
            if (_filterValues) {
                let _filterValueToRemove = filterValue;
                if (_filterValues.includes("," + filterValue)) _filterValueToRemove = "," + filterValue;
                if (_filterValues.includes(filterValue + ",")) _filterValueToRemove = filterValue + ",";
                let _updatedFilterValues = _filterValues.replace(_filterValueToRemove,'');

                if (_updatedFilterValues) {
                    url.searchParams.set(filtersParameter, _updatedFilterValues);
                } else {
                    url.searchParams.delete(filtersParameter);
                }
            } else {
                url.searchParams.delete(filtersParameter);
            }
        }

        window.history.pushState({}, '', url);
    }

    /**
     * Update filter and search results
     *
     * @private
     */
    async _updateFiltersAndSearchResults() {
        const _recipeFiltersNSearchResultsContainer = document.getElementById("recipe-search__filters-n-search-results-container");
        const currentUrl = new URL(window.location);
        const updateUrl = new URL(currentUrl.origin + this._updateUrl);
        let _spinner = document.getElementById("loading-spinner");

        _spinner.classList.remove("loading-spinner--hidden");

        currentUrl.searchParams.forEach((filterValue, filterName) => {
            if (filterName !== "page") {
                updateUrl.searchParams.set(filterName, filterValue);
            }
        });

        try {
            const _url = new URL(updateUrl.href);
            let _response = await fetch(_url);
            _recipeFiltersNSearchResultsContainer.innerHTML = await _response.text();
            Factory.initAll(_recipeFiltersNSearchResultsContainer);

            const _filterToggle = document.querySelector(".lirc-m-dropdown-toggle");
            const _filterContainer = document.querySelector(".lirc-o-search-filter-container__filter-target");

            _filterToggle.addEventListener("click", () => {
                _filterContainer.classList.toggle("lirc-o-search-filter-container__filter-target--xs-hidden");
            });

        } catch (err) {
            console.error("Suggest fetch failed", err);
        }

        _spinner.classList.add("loading-spinner--hidden");
    }
}

ComponentFactory.registerComponent(LidlRecipesMSuggestField);
