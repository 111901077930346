/**
 * @typedef List
 * @property {string} id Identifier for the list
 * @property {string} title Title of the list
 * @property {string} eTag ETag
 */

/**
 * @typedef Category
 * @property {string | null} [merchandiseFamilyId]
 * @property {string | null} [merchandiseGroupId]
 * @property {string | null} [merchandiseSubGroupId]
 */

/**
 * @typedef Image
 * @property {string | null} [url] The url of the image
 */

/**
 * @typedef Images
 * @property {Image} [thumbnail] Thumbnail iamge
 * @property {Image} [medium] Medium resolution image
 * @property {Image} [big] Large resolution image
 * @property {Image} [original] Original image
 */

/**
 * @typedef IdentifiedItem
 * @property {'FreeText' | 'ProductCatalog'} type
 * @property {string} id
 * @property {string} title The title of the list
 * @property {number} quantity
 * @property {boolean} isChecked
 * @property {string | null} [brand]
 * @property {string | null} [comment]
 * @property {string | null} [productId]
 * @property {Images} [images]
 * @property {Category} [category]
 * @property {string | null} [country]
 * @property {string | null} [couponId]
 */

/**
 * @typedef UpdateList
 * @property {string[]} itemIds Sorted of item id's
 * @property {IdentifiedItem[]} items Items to patch to the list
 */

/**
 * Constructor for the shopping list api service
 * 
 * @param {string} baseUrl The base url for the Shopping list API
 * @returns A shopping list service instance
 */
export default class ShoppingListService {
    constructor(baseUrl) {
        /**
         * Base URL for the Shopping List API
         * 
         * @private
         * @type {string}
         */
        this._baseUrl = baseUrl;

        /**
         * Request configuration
         * 
         * @private
         * @property {{'Content-Type': string, Authorization?: string}} header Request headers
         */
        this._config = {
            headers: {
                'Content-Type': 'application/json; charset=UTF-8',
                'accept': 'application/json'
            }
        };
    }

    /**
     * Internal request handler
     * 
     * @private
     * @async
     * @function _request
     * @param {('GET'|'POST'|'PUT'|'PATCH'|'DELETE')} method HTTP method to use for the request
     * @param {string} path Relative path for the function to invoke
     * @param {{query?: string, body?: any, headers?: { [key: string]: string}}} [params] (optional) Request parameters
     * @returns {Promise<Response>}
     */
    async _request(method, path, params) {
        const queryString = params && params.query ? `?${params.query}` : '';
        const resolvePath = `${this._baseUrl}${path}${queryString}`;
        const body = params && params.body ? JSON.stringify(params.body) : undefined;
        const headers = params && params.headers ? { ...this._config.headers, ...params.headers } : { ...this._config.headers };
        try {
            return fetch(resolvePath, {
                method,
                headers,
                body
            });
        } catch (err) {
            console.error(`Error performing request`, err);
            throw err;
        }
    }

    /**
     * Provides the access token to use for future requests
     * 
     * @function setAccessToken
     * @param {string} accessToken The access token for the current user
     * @returns {void}
     */
    setAccessToken(accessToken) {
        this._config.headers.Authorization = `Bearer ${accessToken}`;
    }

    /**
     * Gets a list of shopping lists for the current user
     * 
     * @async
     * @function getLists
     * @param {string | undefined} [etag] (optional) ETag to get a delta of changes since that etag
     * @returns {Promise<{ok: boolean, statusCode: number, status: string, data: { etag?: string, lists?: List[] }}>}
     */
    async getLists(etag) {
        const response = await this._request('GET', '/api/v2/lists', etag ? { headers: { 'User-ETag': etag } } : undefined);
        const statuses = {
            200: 'Success',
            304: 'Not Modified',
            400: 'Bad Request',
            401: 'Unauthorized',
            500: 'Server Error'
        };
        const data = {
            etag: response.headers.get('User-Etag')
        };
        if (response.status === 200) {
            const result = await response.json();
            if (result.lists) {
                data.lists = result.lists;
            }
        }
        return {
            ok: response.status === 200,
            statusCode: response.status,
            status: statuses[response.status] ? statuses[response.status] : 'Unknown',
            data
        };
    }

    /**
     * Creates a shopping list with the given title and returns the id of the created list
     * 
     * @async
     * @function createList
     * @param {string} title The title of the shopping list to create
     * @param {string} [listId] (optional) The identifier to be used when creating the list
     * @returns {Promise<{ok: boolean, statusCode: number, status: string, data: { id?: string, eTag?: string }>}
     */
    async createList(title, listId) {
        const body = {
            listId: listId,
            listTitle: title
        };
        const response = await this._request('POST', '/api/v2/lists', {
            body
        });
        const statuses = {
            201: 'Created',
            400: 'Bad Request',
            401: 'Unauthorized',
            409: 'Conflict',
            500: 'Server Error'
        };
        const data = {};
        if (response.status === 201) {
            const result = await response.json();
            data.id = result.id;
            data.eTag = response.headers.get('List-Etag');
        }
        return {
            ok: response.status === 201,
            statusCode: response.status,
            status: statuses[response.status] ? statuses[response.status] : 'Unknown',
            data
        };
    }

    /**
     * Delete's the specified list
     * 
     * @async
     * @function deleteList
     * @param {string} listId Identifier of the list to be deleted
     * @returns {Promise<{ok: boolean, statusCode: number, status: string}>}
     */
    async deleteList (listId) {
        const response = await this._request('DELETE', `/api/v2/lists/${listId}`);
        const statuses = {
            204: 'Deleted',
            400: 'Bad Request',
            401: 'Unauthorized',
            404: 'Not Found',
            409: 'Conflict',
            500: 'Server Error',
        };
        return {
            ok: response.status === 204,
            statusCode: response.status,
            status: statuses[response.status] ? statuses[response.status] : 'Unknown'
        }
    }

    /**
     * Fetches the current data for the specified list
     * 
     * @async
     * @function getList
     * @param {string} listId The identifier of the list to fetch
     * @param {string} [eTag] (optional) Version tag for the list to be fetch
     * @returns {Promise<{ok: boolean, statusCode: number, status: string, data?: { eTag: string, items?: IdentifiedItem[] | null }}>}
     */
    async getList(listId, eTag) {
        const response = await this._request('GET', `/api/v2/lists/${listId}/items`, eTag ? { headers: { 'List-ETag': eTag } } : undefined);
        const statuses = {
            200: 'Ok',
            304: `Not Modified`,
            400: `Bad Request`,
            401: `Unauthorized`,
            403: `Forbidden`,
            500: `Server Error`,
        };
        const data = {
            eTag: response.headers.get('List-Etag')
        };
        if (response.status === 200) {
            const result = await response.json();
            data.items = result.items;
        }
        return {
            ok: response.status === 200 || response.status === 304,
            statusCode: response.status,
            status: statuses[response.status] ? statuses[response.status] : 'Unknown',
            data
        };
    }

    /**
     * Deletes all items from the specified list
     * 
     * @param {string} listId The id of the list to be cleared
     * @returns {{ ok: boolean, statusCode: number, status: string }}
     */
    async clearList(listId) {
        const listReponse = await this.getList(listId);
        if (listReponse.ok) {
            if (listReponse.data.items.length > 0) {
                return await this.deleteItems(listId, listReponse.data.eTag, listReponse.data.items.map(item => item.id));
            } else {
                // Nothing to delete because the list is empty, so return success
                return {
                    ok: true,
                    statusCode: listReponse.statusCode,
                    status: listReponse.status
                };
            }
        } else {
            return {
                ok: false,
                statusCode: listReponse.statusCode,
                status: listReponse.status
            };
        }
    }

    /**
     * Deletes the specified items from the specified list
     * 
     * @param {string} listId The id of the list to delete the items from
     * @param {string} listETag The current version eTag of the list
     * @param {string[]} itemIds A list of item ids to be removed from the list
     * @returns {{ ok: boolean, statusCode: number, status: string }}
     */
    async deleteItems(listId, listETag, itemIds) {
        const response = await this._request("POST", `/api/v2/lists/${listId}/deleteItems`, { 
            headers: { 'List-ETag': listETag },
            body: { itemIds } 
        });
        const statuses = {
            200: 'Ok',
            400: `Bad Request`,
            401: `Unauthorized`,
            403: `Forbidden`,
            404: `Not Found`,
            409: `Conflict`,
            500: `Server Error`,
        };
        return { 
            ok: response.status === 200,
            statusCode: response.status,
            status: statuses[response.status] ? statuses[response.status] : 'Unknown'
        };
    }

    /**
     * Adds the specified items to the list
     * 
     * @async
     * @function updateList
     * @param {string} listId Identifier of the list to modify
     * @param {string} listETag Version of the list to modify
     * @param {UpdateList} body Update list data
     * @returns {Promise<{ok: boolean, statusCode: number, status: string}>}
     */
    async updateList(listId, listETag, body) {
        const response = await this._request('PATCH', `/api/v2/lists/${listId}`, {
            headers: { 'List-ETag': listETag },
            body
        });

        const statuses = {
            200: 'Success',
            400: `Bad Request`,
            401: `Unauthorized`,
            409: `Conflict`,
            500: `Server Error`,
        };

        return {
            ok: response.status === 200,
            statusCode: response.status,
            status: statuses[response.status] ? statuses[response.status] : 'Unknown',
        };
    }
}
