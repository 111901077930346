import "@nucleus/polyfill/src/_polyfill";
import { Component, ComponentFactory, Factory } from "@nucleus/core/src/_core";
import LidlRecipesOSearchDropdownCheckbox from "@lidl-recipes/o-search-dropdown-checkbox";
import NucleusAInput from "@nucleus/a-input";
import NucleusMCheckbox from "@nucleus/m-checkbox";
import { NunjucksWebWrapper } from "@nucleus-tools/nunjucks-web";
import { i18n } from "@nucleus/core/src/_i18n";
import LidlRecipesMFilterChip from "@lidl-recipes/m-filter-chip";
import EventUtils from "@nucleus/core/src/_eventUtils";

export default class LidlRecipesORecipeSearch extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-recipe-search";
    }

    /**
     * Creates an instance of LidlRecipesORecipeSearch.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._isSearchPage = this.rootElement.dataset.pageType === "search-page";
        this._urlSearch = this.rootElement.dataset.urlSearch;
        const queryString = window.location.search;
        this._urlParams = new URLSearchParams(queryString);
        this._totalResultsText = this.rootElement.querySelector(".lirc-o-recipe-search__total-results-text");
        this._resultsTemplate = this.rootElement.dataset.resultsTemplate;
        this._textMinutes = this.rootElement.dataset.minutes;
        this._textHours = this.rootElement.dataset.hours;
        this._textDifficulty = this.rootElement.dataset.difficultyLabel;
        this._textTime = this.rootElement.dataset.timeLabel;
        this._textAddToFavorites = this.rootElement.dataset.addToFavorites;
        this._textRemoveFromFavorites = this.rootElement.dataset.removeFromFavorites;
        this._urlDetail = this.rootElement.dataset.urlDetail;
        this._urlDefaultChefImage = this.rootElement.dataset.urlDefaultChefImage;
        this._urlFavorites = this.rootElement.dataset.urlFavorites;
        this._difficultyMap = {
            1: this.rootElement.dataset.difficultyEasy,
            2: this.rootElement.dataset.difficultyMedium,
            3: this.rootElement.dataset.difficultyDifficult
        };
        this._difficultyMapTracking = {
            1: "low",
            2: "medium",
            3: "high"
        };
        this._showLoadingSpinner = null;
        this._currentPage = parseInt(this.rootElement.dataset.currentPage);
        this._recipeIndex = 36;

        /**
         *
         * @type {Component}
         * @private
         */
        this._loadingSpinner = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__loading-spinner"));

        /**
         *
         * @type {Component}
         * @private
         */
        this._appliedFiltersCount = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__applied-filters-count"));

        /**
         *
         * @type {Component}
         * @private
         */
        this._loadingContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__loading-container"));

        /**
         *
         * @type {Component}
         * @private
         */
        this._loadMoreContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__load-more-container"));

        /**
         *
         * @type {Component}
         * @private
         */
        this._resultsList = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__results-list"));

        /**
         *
         * @type {Component}
         * @private
         */
        this._resultsContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__results-container"));

        /**
         *
         * @type {Component}
         * @private
         */
        this._noResultsContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__no-results-container"));

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._loadMoreButton = ComponentFactory.getInstance(this.querySelector(".lirc-o-recipe-search__load-more-button"));
        this._loadMoreButton.clickSubject.subscribe(() => {
            this._currentPage++;
            this._setResultsDebounced();
        });

        /**
         * @type {Function}
         * @private
         */
        this._setResultsDebounced = EventUtils.debounce(() => this._setResults(), 30);

        // The rest should only be applied, in case we are on the search page.
        if(!this._isSearchPage) return;

        this._showResultsTemplate = this.rootElement.dataset.showResultsTemplate;
        this._filterForm = this.rootElement.querySelector(".lirc-o-recipe-search__filter-panel");
        this._urlSearch = this._filterForm.action;
        this._textRemoveFilter = this.rootElement.dataset.removeFilter;

        /**
         * @type {Component}
         * @private
         */
        this._filterChipContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__filter-chip-container"));

        /**
         * @type {NucleusMFlexContainer}
         * @private
         */
        this._filterChipList = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__filter-chip-list"));

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._filterShowButton = ComponentFactory.getInstance(this.querySelector(".lirc-o-recipe-search__filter-show-button"));
        this._filterShowButton.clickSubject.subscribe(() => {
            this.addClass("lirc-o-recipe-search--show-filter-panel");
        });

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._filterHideButton = ComponentFactory.getInstance(this.querySelector(".lirc-o-recipe-search__filter-hide-button"));
        this._filterHideButton.clickSubject.subscribe(() => {
            this.removeClass("lirc-o-recipe-search--show-filter-panel");
        });

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._showResultsButton = ComponentFactory.getInstance(this.querySelector(".lirc-o-recipe-search__show-results-button"));
        this._showResultsButton.clickSubject.subscribe(() => {
            this.removeClass("lirc-o-recipe-search--show-filter-panel");
        });

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._chipResetButton = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__chip-reset-button"));
        this._chipResetButton.clickSubject.subscribe(() => this._resetAll());

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._filterResetButton = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__filter-reset-button"));
        this._filterResetButton.clickSubject.subscribe(() => this._resetAll());

        window.addEventListener('click', (event) => {
            if (this._filterForm.contains(event.target) || this._filterShowButton.rootElement.contains(event.target)) return;
            this.removeClass("lirc-o-recipe-search--show-filter-panel");
        });

        /**
         * @type {NucleusMCheckbox[]}
         * @private
         */
        this._filterInputs = ComponentFactory.getInstances(this._filterForm.querySelectorAll(NucleusMCheckbox.rootSelector));

        /**
         * @type {LidlRecipesOSearchDropdownCheckbox[]}
         * @private
         */
        this._filterDropdowns = ComponentFactory.getInstances(this._filterForm.querySelectorAll(LidlRecipesOSearchDropdownCheckbox.rootSelector));
        this._filterDropdowns.forEach(dropdown => {
            /**
             * @type {NucleusAInput[]}
             * @private
             */
            const dropdownFilterInputs = ComponentFactory.getInstances(dropdown.querySelectorAll(NucleusAInput.rootSelector));
            dropdownFilterInputs.forEach((input,index) => {
                input.changeSubject.subscribe((element, event) => {
                    if (input.checked) {
                        this._addChip(input);
                        this._addFilterToUrlParams(
                            dropdown.dataset.filterId,
                            input.name
                        );
                    } else {
                        this._removeFilterFromUrlParams(input.name);
                        this._removeChip(input.name);
                    }
                    this._currentPage = 1;
                    this._recipeIndex = 0;
                    this._setResultsDebounced();

                    const itemsToPush = {
                        itemName: dropdown.trackingId,
                        filterType: input.labelText,
                        position: index + 1
                    }
                    this._pushGaClick(itemsToPush);
                });
            });
        });

        this._initFilterChips();

        if (document.readyState !== 'loading') {
            this._scrollToPage();
        } else {
            document.addEventListener('DOMContentLoaded', () => this._scrollToPage());
        }

        /**
         * @type {NucleusASelect}
         * @private
         */
        this._orderSelect = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__order-select-field"));
        this._orderSelect.changeSubject.subscribe((element, event) => {
            if (!event) return;
            let value = event.target.value;
            this._setSorting(value);
            this._orderSelectMobile.value = value;
        });

        /**
         * @type {NucleusASelect}
         * @private
         */
        this._orderSelectMobile = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-recipe-search__order-select-field-mobile"));
        this._orderSelectMobile.changeSubject.subscribe((element, event) => {
            if (!event) return;
            let value = event.target.value;
            this._setSorting(value);
            this._orderSelect.value = value;
        });
    }

    /**
     * Set sorting.
     * @param value
     * @private
     */
    _setSorting(value) {
        const itemsToPush = {
            itemName: 'filters_sortbutton',
            filterType: value,
        }

        this._pushGaClick(itemsToPush);
        this._currentPage = 1;
        this._recipeIndex = 0;
        this._urlParams.set("sortBy", value);
        this._setResultsDebounced();
    }

    /**
     * Scroll page into view.
     * @private
     */
    _scrollToPage() {
        let page = this._urlParams.get('page');
        if (!page || page === "1") return;

        let pageElement = this.rootElement.querySelector(`#result-page-${page}`);
        if (pageElement) pageElement.scrollIntoView();
    }

    /**
     * Reset Filters.
     * @private
     */
    _resetAll() {
        this._filterChipContainer.hide();
        [...this._urlParams.keys()]
          .filter(key => key.startsWith('filters['))
          .forEach(key => this._urlParams.delete(key));
        [...this._filterInputs].forEach(input => input.checked = false);
    }

    /**
     * Object passed to template via Nunjucks.
     * @param data
     * @returns {{component: string, htmlAttributes: {"data-filter-id", class: string}, text: (string|null|*), objectButton: {component: string}}}
     * @private
     */
    _filterChipObject(data) {
        return {
            component: "nucleus/a-flex-item",
            tag: "li",
            width: "self",
            gutter: 8,
            hasShowOverflow: true,
            embedded: [
                {
                    component: "lidl-recipes/m-filter-chip",
                    textLabel: data.labelText,
                    textRemoveButtonTitle: this._textRemoveFilter,
                    textFilterId: data.name
                }
            ]
        };
    }

    /**
     * Initialise filter chips.
     * @private
     */
    _initFilterChips() {
        /**
         * @type {LidlRecipesMFilterChip[]}
         * @private
         */
        this._filterChips = ComponentFactory.getInstances(this._filterChipList.querySelectorAll(LidlRecipesMFilterChip.rootSelector));
        this._filterChips.forEach(item => {
            item.removeButton.clickSubject.subscribe(() => {
                let input = [...this._filterInputs].find(input => input.name === item.filterId);
                input.checked = false;
            });
        });
        this._updateAppliedFiltersCount(this._filterChips.length);
    }

    /**
     * Add filter chip method.
     * @param data
     * @returns {Promise<void>}
     * @private
     */
    async _addChip(data) {
        this._filterChipList.embeddedElement.insertAdjacentHTML(
            "beforeend",
            await NunjucksWebWrapper.renderComponent(
                this._filterChipObject(data)
            )
        );
        Factory.initAll(this._filterChipList.rootElement);

        this._initFilterChips();
        this._filterChipContainer.show();
    }

    /**
     * Remove filter chip method.
     * @param filterId
     * @private
     */
    _removeChip(filterId) {
        let filterChips = ComponentFactory.getInstances(this._filterChipContainer.querySelectorAll(LidlRecipesMFilterChip.rootSelector));
        let chip = [...filterChips].find(chip => chip.filterId === filterId);
        chip.rootElement.closest(".nuc-a-flex-item").remove();

        let updatedChips = this._filterChipContainer.querySelectorAll(LidlRecipesMFilterChip.rootSelector);
        if (updatedChips.length === 0) {
            this._filterChipContainer.hide();
        }
        this._updateAppliedFiltersCount(updatedChips.length);
    }

    /**
     * @param filterId
     * @private
     */
    _removeFilterFromUrlParams(filterId) {
        [...this._urlParams.keys()].forEach((key) => {
            if (key.startsWith('filters[')) {
                const valuesArray = this._urlParams.get(key)
                    .split(',')
                    .filter(value => value !== filterId);

                if (valuesArray.length === 0) {
                    this._urlParams.delete(key);
                } else {
                    this._urlParams.set(key, valuesArray.join(','));
                }
            }
        });
    }

    /**
     * @param category
     * @param id
     * @private
     */
    _addFilterToUrlParams(category, id) {
        const key = `filters[${category}]`;
        const valuesArray = this._urlParams.has(key)
            ? this._urlParams.get(key).split(",")
            : [];

        if (!valuesArray.includes(id)) {
            valuesArray.push(id);
            this._urlParams.set(key, valuesArray.join(','));
        }
    }

    /**
     * Build result card object to be passed to nunjucks.
     * @param data
     * @param id
     * @param totalAmount
     * @param index
     * @return {Promise<{component: string, hasShowOverflow: boolean, width: {md: string, sm: string, xs: string}, tag: string, embedded: [{textDifficultyLevel: *, component: string, htmlAttributes: {id: null}, textTime: (*|string), textTimeLabel: *, textDifficultyLevelLabel: *, objectPicture: {embeddedSources: [{urlSourceLow: *, component: string, viewport: string, urlSource: *, urlSource2x: *}], component: string, textAlternative, ratio: string}, textHeadline, objectAnchor: {component: string, urlReference: string}}]}>}
     * @private
     */
    async _resultCardObject(data, id = null, totalAmount, index) {
        const chef = data.chefs && data.chefs[0] || null;
        const chefAdditional = data.chefs && data.chefs[1] || null;
        let chefName = chef ? chef.name : "";
        let chefNameAdditional = chefAdditional ? `, ${chefAdditional.name}` : "";

        let chefAdditionalMeta = chefAdditional ? {
            textChefNameAdditional: chefAdditional.name || null,
            objectImageAdditional: {
                component: "nucleus/a-image",
                urlSource: chef.imageVariations && chef.imageVariations[1] && chef.imageVariations[1].storageUrl || this._urlDefaultChefImage,
                textAlternative: chefAdditional.name
            }
        } : null;

        let chefOverlay = chef ? {
            ...chefAdditionalMeta,
            component: "lidl-recipes/o-card-overlay-chef",
            textChefName: chef.name || null,
            textSeparator: "&",
            objectImage: {
                component: "nucleus/a-image",
                urlSource: chef.imageVariations && chef.imageVariations[0] && chef.imageVariations[0].storageUrl || this._urlDefaultChefImage,
                textAlternative: chef.name
            }
        } : null;

        let favoriteButton = this._urlFavorites ? {
            component: "lidl-recipes/m-favorite-button",
            textAdd: this._textAddToFavorites,
            textRemove: this._textRemoveFromFavorites,
            recipeId: data.id,
            urlFavorites: this._urlFavorites,
            gaRecipeName: data.name,
            gaChef: !chefName && !chefNameAdditional ? null : `${chefName}${chefNameAdditional}`,
            gaTime: data.cookingTime + data.preparationTime,
            gaQuantity: totalAmount,
            gaDifficulty: this._difficultyMapTracking[data.difficulty],
            gaPosition: index
        } : null;

        return {
            component: "nucleus/a-flex-item",
            tag: "li",
            hasShowOverflow: true,
            width: {
                xs: "12",
                sm: "6",
                md: "4"
            },
            embedded: [{
                component: "lidl-recipes/o-card",
                htmlAttributes: {
                    id: id || null
                },
                textHeadline: data.name,
                textDifficultyLevel: this._difficultyMap[data.difficulty],
                textDifficultyLevelLabel: this._textDifficulty,
                textTime: this._formatMinutes(data.cookingTime + data.preparationTime, this._textMinutes, this._textHours),
                textTimeLabel: this._textTime,
                objectPicture: {
                    component: "nucleus/m-picture",
                    ratio: "16/9",
                    textAlternative: data.name,
                    embeddedSources: [
                        {
                            component: "nucleus/a-source",
                            viewport: "xs",
                            urlSource: data.imageVariations[0].storageUrl,
                            urlSource2x: data.imageVariations[0].storageUrl,
                            urlSourceLow: data.imageVariations[0].storageUrl
                        }
                    ]
                },
                objectAnchor: {
                    component: "nucleus/a-anchor",
                    urlReference: `${this._urlDetail}/${data.slug}`
                },
                embeddedOverlay: [
                    chefOverlay,
                    favoriteButton
                ]
            }]
        };
    }

    /**
     * @returns {Promise<void>}
     * @private
     */
    async _setResults() {
        let result = await this._fetchRecipes();

        if (this._currentPage === 1) {
            this._resultsList.embeddedElement.innerHTML = "";
        }

        for (const [index, data] of result.recipes.entries()) {
            this._recipeIndex++;

            let id = index === 0 && `result-page-${result.currentPage}`;
            this._resultsList.embeddedElement.insertAdjacentHTML(
                "beforeend",
                await NunjucksWebWrapper.renderComponent(
                    await this._resultCardObject(data, id, result.totalAmountOfRecipes, this._recipeIndex )
                )
            );
            Factory.initAll(this._resultsList.rootElement);
        }

        if (result.totalAmountOfPages == result.currentPage) {
            this._loadMoreContainer.hide();
        } else {
            this._loadMoreContainer.show();
        }

        if (result.totalAmountOfRecipes === 0) {
            this._noResultsContainer.show();
            this._resultsContainer.hide();
        } else {
            this._noResultsContainer.hide();
            this._resultsContainer.show();
        }

        if (!this._isSearchPage) return;

        Object.values(result.filters).flat().forEach(item => {
            let checkbox = this._filterInputs.find(input => input.name === item.id);
            if (checkbox) {
                checkbox.disabled = item.isDisabled;
                checkbox.count = item.number;
            }
        });

        this._updateResultInfoText(result.totalAmountOfRecipes);
        this._updateShowResultInfoButton(result.totalAmountOfRecipes);
    }

    /**
     * @param response
     * @returns {Promise<*>}
     * @private
     */
    async _handleResponse(response) {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        if (!result || Object.keys(result).length === 0) {
            throw new Error('Result is empty');
        }
        return result;
    }

    /**
     * @returns {Promise<void>}
     * @private
     */
    async _fetchRecipes() {
        this._urlParams.set("page", this._currentPage.toString());
        this._isLoading(true);

        if(this._isSearchPage) {
            this._filterInputs.forEach(input => {
                input.inactive = true;
            });
        };

        try {
            const url = new URL(this._urlSearch);
            if(this._isSearchPage) {
                url.search = this._urlParams.toString();
                window.history.pushState(null, null, "?" + this._urlParams.toString());
            } else {
                url.searchParams.set('page', this._currentPage.toString());
            }
            return await this._handleResponse(await fetch(url));
        } catch (error) {
            console.error('An error occurred:', error);
        } finally {
            this._isLoading(false);
            if(this._isSearchPage) {
                this._filterInputs.forEach(input => {
                    input.inactive = false;
                });
            }
        }
    }

    /**
     * Update result information (Number of results).
     * @param resultsNum
     * @private
     */
    _updateResultInfoText(resultsNum) {
        this._totalResultsText.innerHTML = i18n.pluralize(this._resultsTemplate, { count: resultsNum }, resultsNum);
    }

    /**
     * Update result information on mobile show-results button.
     * @param resultsNum
     * @private
     */
    _updateShowResultInfoButton(resultsNum) {
        this._showResultsButton.text = i18n.pluralize(this._showResultsTemplate, { count: resultsNum }, resultsNum);
    }

    /**
     * @param count
     * @private
     */
    _updateAppliedFiltersCount(count) {
        if (count > 0) {
            this._appliedFiltersCount.rootElement.innerHTML = `(${count})`;
            this._appliedFiltersCount.show();
            return;
        }
        this._appliedFiltersCount.hide();
    }

    /**
     * Format minutes.
     * @param totalMinutes
     * @param minText
     * @param hoursText
     * @returns {*|string}
     * @private
     */
    _formatMinutes(totalMinutes, minText, hoursText) {
        if (totalMinutes <= 59) {
            return `${totalMinutes} ${minText}`;
        }

        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        if (minutes === 0) {
            return `${hours} ${hoursText}`;
        }

        return `${hours}:${minutes.toString().padStart(2, '0')} ${hoursText}`;
    }

    /**
     * Push to the data layer in GTM.
     * @param items
     * @private
     */
    _pushGaClick(items) {
        if (!window.dataLayer) {
            console.warn("[nucleus] GTM variable dataLayer is not available.");
            window.dataLayer = [];
        }

        window.dataLayer.push({
            event: "tap_item",
            ...items
        });
    }

    /**
     * Handle loading overlay.
     * @param isLoading
     * @private
     */
    _isLoading(isLoading) {
        if (isLoading) {
            this._loadingContainer.show();
            this._showLoadingSpinner = setTimeout(() => {
                this._loadingSpinner.show();
            }, 2000);
            return;
        }
        clearTimeout(this._showLoadingSpinner);
        this._loadingContainer.hide();
        this._loadingSpinner.hide();
    }
}

ComponentFactory.registerComponent(LidlRecipesORecipeSearch);
