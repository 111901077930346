import "@nucleus/polyfill/src/_polyfill";
import {Factory} from "@nucleus/core/src/_core";
import NucleusAButton from "@nucleus/a-button";

export default class LidlRecipesMDropdownToggle extends NucleusAButton {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-m-dropdown-toggle";
    }

    /**
     * Creates an instance of LidlRecipesMDropdownToggle.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this.clickSubject.subscribe( () => {
            const _openState = this.rootElement.getAttribute("data-open");

            if(_openState === null) {
                this.rootElement.setAttribute("data-open", "open");
            } else {
                this.rootElement.removeAttribute("data-open");
            }
        });
    }
}

Factory.registerComponent(LidlRecipesMDropdownToggle);
