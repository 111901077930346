/**
 * Keep this extension of nunjucks in sync with the Twig version of it in Nucleus\CoreBundle\Twig\CoreExtension.
 */
export default class WebAssetExtension {

    constructor(nunjucksWrapper) {
        nunjucksWrapper.environment.addGlobal("get_asset_namespace", () => this.getAssetNamespace());
    }

    getAssetNamespace() {
        return "nucleus";
    }
}
