import NunjucksCore from "@nucleus-tools/nunjucks-core";
import {Config} from "@nucleus/core/src/_config.js";
import NucleusWebLoader from "./nucleus-web-loader.js";
import WebAssetExtension from "./web-asset-extension.js";

class _NunjucksWebWrapper extends NunjucksCore {

    constructor() {
        super(
            new NucleusWebLoader(),
            {
                assetManifestPaths: {
                    "nucleus": Config.assetManifestPath
                },
                nucleusManifestPath: Config.nucleusManifestPath
            }
        );

        this._extensions.push(new WebAssetExtension(this));

        this._environment.addGlobal("clientSideRendering", true);
        this._assetManifests = {};
    }

    getAssetManifest(packageName) {
        return this._assetManifests[packageName];
    }

    getNucleusManifest() {
        return this._nucleusManifest;
    }

    _loadAssetManifest() {
        return fetch(Config.assetManifestPath)
            .then(response => response.json())
            .then(assetManifest => this._assetManifests["nucleus"] = assetManifest);
    }

    _loadNucleusManifest() {
        return fetch(Config.nucleusManifestPath)
            .then(response => response.json())
            .then(nucleusManifest => {
                this._nucleusManifest = nucleusManifest;
            });
    }
}

export const NunjucksWebWrapper = new _NunjucksWebWrapper();
