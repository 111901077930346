import "@nucleus/polyfill/src/_polyfill";
import { Component, ComponentFactory } from "@nucleus/core/src/_core";

export default class LidlRecipesAIngredientBoxItem extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-a-ingredient-box-item";
    }

    /**
     * Creates an instance of LidlRecipesAIngredientBoxItem.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._ingredientId = rootElement.dataset.ingredientId;
        this._quantityFrom = rootElement.dataset.quantityFrom;
        this._quantityTo = rootElement.dataset.quantityTo;
        this._quantity = this._quantityFrom;
        this._unitSingular = rootElement.dataset.unitSingular;
        this._unitPlural = rootElement.dataset.unitPlural;
        this._nameSingular = rootElement.dataset.nameSingular;
        this._namePlural = rootElement.dataset.namePlural;
        this._baseServingQuantity = rootElement.dataset.baseServingQuantity;
        this._decimalSeparator = rootElement.dataset.decimalSeparator;
        this._amountFromContainer = rootElement.querySelector(".lirc-a-ingredient-box-item__amount-from");

        const isSelectedInputElement = rootElement.querySelector(`input[id="${this._ingredientId}"]`);
        if (isSelectedInputElement) {
            /**
             * @type {NucleusAInput}
             * @private
             */
            this._isSelectedInput = ComponentFactory.getInstance(isSelectedInputElement);
        }

        /**
         * @type {Component}
         * @private
         */
        this._amountToContainer = ComponentFactory.getInstance(
            rootElement.querySelector(".lirc-a-ingredient-box-item__amount-to-container")
        );

        this._unit = rootElement.querySelector(".lirc-a-ingredient-box-item__unit");
        this._name = rootElement.querySelector(".lirc-a-ingredient-box-item__name");
    }

    /**
     * P
     * @param quantity
     */
    servingAmount(quantity) {
        if(this._amountFromContainer) {
            this._quantity = (this._quantityFrom / this._baseServingQuantity) * quantity;
            const formattedAmount = this._quantity % 1 === 0 ? this._quantity.toFixed(0) : this._quantity.toFixed(2);
            this._amountFromContainer.innerText = formattedAmount.replace(".", this._decimalSeparator);
            this._name.innerText = this._quantity === 1 ? this._nameSingular : this._namePlural;

            if(this._unit) {
                this._unit.innerText = this._quantity === 1 ? this._unitSingular : this._unitPlural;
            }
        }

        if(!this._amountToContainer) return;
        this._amountToContainer.hide();
    }

    /**
     * A shopping list entry describes an ingredient for a shopping list
     * 
     * @typedef {Object} ShoppingListEntry
     * @property {string} id - Identifier for this entry
     * @property {string} type - The type of this shopping list entry, always "FreeText"
     * @property {string} title - The title of this ingredient
     * @property {boolean} checked - Is this shopping list entry checked? Always "false"
     * @property {number} quantity - The quantity required of this ingredient
     * @property {string} [comment] - A comment describing the units to use for this ingredient
     */

    /**
     * Generates a shopping list entry that describes this ingredient.
     * 
     * @param {string} id - The identifier to use for this ingredient
     * @returns {ShoppingListEntry}
     */
    getShoppingListEntry() {
        return {
            id: this._ingredientId,

            // type is always FreeText since we are only providing a description of the ingredient
            type: "FreeText",

            // take the current value of the name field for the title
            title: this._name.innerText,

            // always set checked to false since this is a new entry
            isChecked: false,

            // if no quantity is specified, then the quantity is 1
            // if a _amount is specified but no units are specified, then the quantity is _amount
            // if _amount is specified AND this._unit is specified, then quantity is 1 and we
            //      write "_amount _unit" as a comment
            // Note that quantity must be an integer, so we call Math.ceil on the _amount
            quantity: this._amountFromContainer && this._unit ? 1 : this._amountFromContainer ? Math.ceil(this._quantity) : 1,

            // If we have an _amount and a _unit, use the comment to describe the quantity
            comment: this._amountFromContainer && this._unit ? `${this._quantity} ${this._unit.innerText}` : undefined
        };
    }

    /**
     * Check if this ingredient item is selected for the shopping list
     * 
     * @returns {boolean}
     */
    isSelected() {
        return this._isSelectedInput && this._isSelectedInput.checked;
    }
}

ComponentFactory.registerComponent(LidlRecipesAIngredientBoxItem);
