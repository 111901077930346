import "@nucleus/polyfill/src/_polyfill";
import { Component, Factory } from "@nucleus/core/src/_core";

export default class LidlRecipesMIngredientBox extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-m-ingredient-box";
    }

    /**
     * Creates an instance of LidlRecipesMIngredientBox.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);
    }
}

Factory.registerComponent(LidlRecipesMIngredientBox);
