import { Component, ComponentFactory, Subject } from "@nucleus/core/src/_core";

export default class LidlRecipesASuggestFieldItem extends Component {

    static get rootClassName() {
        return "lirc-a-suggest-field-item";
    }

    /**
     * Creates an instance of LidlRecipesASuggestFieldItem.
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        /**
         * @type {HTMLElement}
         * @private
         */
        this._label = this.querySelector(".lirc-a-suggest-field-item__label");

        /**
         * @type {String}
         * @private
         */
        this._checkboxLabelText = this.dataset.label;

        this._modifiers = {
            item: {
                focus: "lirc-a-suggest-field-item--focused"
            }
        };
    }

    /**
     * @returns {string}
     */
    get textLabel() {
        if (this._label) return this._label.innerText;
        return this._checkboxLabelText;
    }

    /**
     * Add focus
     */
    focus() {
        this.addClass(this._modifiers.item.focus);
        this.rootElement.setAttribute("aria-selected", "true");
    }

    /**
     * Remove focus
     */
    blur() {
        this.removeClass(this._modifiers.item.focus);
        this.rootElement.removeAttribute("aria-selected");
    }
}

ComponentFactory.registerComponent(LidlRecipesASuggestFieldItem);
