import { Subject } from '@nucleus/core/src/_core';
import { getCookie } from './cookies';

class UserService {

    /** 
     * Indicates if the current user appears to be authenticated
     * Note that this doesn't validate the token against the server
     * A user is considered "signed in" if they appear to have the
     * correct cookies.
     * 
     * @returns {boolean} 
     */
    get isSignedIn() {
        // The presense of the user token indicates the user is signed in
        // even if no authToken is present
        return !!this._userToken;
    }

    /**
     * The user id of the current user, if the current user is signed in
     * with a valid user token
     * 
     * @returns {string | null}
     */
    get userId() {
        return this._userId;
    }

    /**
     * Subject for the logout event
     * @returns {Subject}
     */
    get logoutSubject() {
        return this._logoutSubject;
    }

    /** 
     * Base url for performing requestions again the server
     * e.g. "https://recipes.lidl.co.uk"
     * 
     * @property {string} 
     */
    baseUrl = `${window.location.protocol}//${window.location.hostname}`;

    constructor() {
        /**
         * @type {Subject}
         * @private
         */
        this._logoutSubject = new Subject();
        
        /**
         * @type {string|null}
         * @private
         */
        this._userToken = null;

        this._readCookies();
    }

    /**
     * Gets the active users authToken, refreshing it if necessary
     * 
     * @function getAuthToken
     * @returns {Promise<string | null>}
     */
    async getAuthToken() {
        const authToken = getCookie("authToken");
        if (authToken) {
            return authToken;
        } else if (this.isSignedIn) {
            return await this._refreshAuthToken();
        } else {
            return null;
        }
    }

    /**
     * Refreshes the active users cookies
     * 
     * @private
     * @function _refreshAuthToken
     * @throws {Error & { loggedOut?: boolean }} Error with optional boolean flag indicated if the user has been logged out
     * @return {Promise<string | null>}
     */
    async _refreshAuthToken() {
        const response = await fetch(`${this.baseUrl}/refresh_token`);
        this._readCookies();

        if (!response.ok) {
            const error = new Error('Failed to restore user session');
            if (response.status === 401) {
                // Refresh token is not valid
                error.loggedOut = true;
                this._logoutSubject.notify(this);
            }
            throw error;
        }

        return getCookie("authToken");
    }

    /**
     * Reads the current user from cookies
     * 
     * @private
     * @function _readCookies
     * @returns {void}
     */
    _readCookies() {
        this._userToken = getCookie("user");
        this._userId = this._userToken
                ? (this._decodeJsonFromUrlEncoded(this._userToken).sub || null)
                : null;
    }

    /**
     * Decodes a URL encoded json string into an object
     * 
     * @private
     * @function _decodeJsonFromUrlEncoded
     * @param {string} encodedString url encoded string to be decoded
     * @returns {any}
     */
    _decodeJsonFromUrlEncoded(encodedString) {
        const decodedString = decodeURIComponent(encodedString);
        return JSON.parse(decodedString);
    }

}

export default new UserService();
