import "@nucleus/polyfill/src/_polyfill";
import { Component, Factory } from "@nucleus/core/src/_core";

export default class LidlRecipesORecipeTeaserBasicList extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-recipe-teaser-basic-list";
    }

    /**
     * Creates an instance of LidlRecipesORecipeTeaserBasicList.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);
    }
}

Factory.registerComponent(LidlRecipesORecipeTeaserBasicList);
