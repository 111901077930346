import "@nucleus/polyfill/src/_polyfill";
import { Component, Factory } from "@nucleus/core/src/_core";

export default class LidlRecipesOMediaTextBlock extends Component {
    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-media-text-block";
    }

    /**
     * Creates an instance of LidlRecipesOMediaTextBlock.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        let _youtubeIcon = rootElement.querySelector(
            "." + this.rootClassName + "__trigger-button"
        );

        let _youtubeIframe = rootElement.querySelector(
            "." + this.rootClassName + "__youtube"
        );

        let _picture = rootElement.querySelector(
            "." + this.rootClassName + "__picture"
        );

        if (_youtubeIcon !== null) {
            _youtubeIcon.addEventListener("click", () => {
                _youtubeIframe.style.display = "block";
                _picture.style.opacity = "0";
                _youtubeIcon.style.display = "none";
            });
        }
    }
}

Factory.registerComponent(LidlRecipesOMediaTextBlock);
