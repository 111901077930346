import "@nucleus/polyfill/src/_polyfill";
import {
    Component,
    ComponentFactory,
    Factory
} from "@nucleus/core/src/_core";
import NucleusAInput from "@nucleus/a-input";
import {
    NunjucksWebWrapper
} from "@nucleus-tools/nunjucks-web";
import NucleusASelect from "@nucleus/a-select";

export default class LircOFilterBadgeContainer extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-filter-badge-container";
    }

    /**
     * Creates an instance of LircOFilterBadgeContainer.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._targetContainer = document.getElementById(this.dataset.targetContainer);
        this._updateUrl = this.dataset.updateUrl;
        this._activeFilters = JSON.parse(this.dataset.activeFilters);
        this._autoSuggestionFieldList = this._targetContainer.querySelectorAll(".lirc-m-search-filter-autosuggestion-field");
        this._wrapper = ComponentFactory.getInstance(this.querySelector(".lirc-o-filter-badge-container__wrapper"));
        this._badgeTemplate = JSON.parse(this.dataset.badgeTemplate);
        this._badges = [];

        this._autoSuggestionFieldList.forEach((_autoSuggestionField) => {
            let _autoSuggestionFieldInput = ComponentFactory.getInstance(_autoSuggestionField.querySelector(".lirc-m-search-filter-autosuggestion-field__input"));
            let _fullList = JSON.parse(_autoSuggestionField.dataset.fullList);
            _autoSuggestionFieldInput.changeSubject.subscribe((_autoSuggestionFieldInput) => {
                this._onFormControlChange(_autoSuggestionFieldInput, _autoSuggestionField, _autoSuggestionFieldInput, _fullList);
            });

            if (this._activeFilters && _autoSuggestionFieldInput.rootElement.dataset.filterName && this._activeFilters[_autoSuggestionFieldInput.rootElement.dataset.filterName]) {
                let _activeFilterIds = this._activeFilters[_autoSuggestionFieldInput.rootElement.dataset.filterName].split(",");
                this._onFormControlChange(_autoSuggestionFieldInput, _autoSuggestionField, _autoSuggestionFieldInput, _fullList, _activeFilterIds);
            }
        });

        this._init();
    }

    /**
     * Initializes the filter badge container.
     *
     * @private
     */
    _init() {
        this.hide();
        this._initResetAllButton();
    }

    _initResetAllButton() {
        this.resetAllBtn = this.rootElement.querySelector('.' + this.rootClassName + '__reset-all-button');
        this.resetAllBtn.addEventListener("click", () => {
            this._resetAllFilters()
        });
    }

    /**
     * Callback for change event of inputs and selects.
     *
     * @param {NucleusAInput|NucleusASelect} formControl
     * @private
     */
    _onFormControlChange(formControl, _autoSuggestionField, _autoSuggestionFieldInput, _fullList, _activeItemIds = []) {
        // Update fullList if a new component was added at runtime
        _fullList = JSON.parse(_autoSuggestionField.dataset.fullList);

        if (formControl instanceof NucleusAInput && (formControl.type === "checkbox" || formControl.type === "radio")) {
            if (formControl.checked) {
                this._createBadge(formControl, _autoSuggestionFieldInput, _autoSuggestionField);
            } else {
                this._deleteBadge(formControl);
            }
        } else {
            const _currentItemId = formControl.getAttribute("data-current-item-id");
            const _badgeDoesNotExist = !(this._badges.map(e => e.itemId).some(e => e === _currentItemId));
            const _isInputValueInList = _fullList.some(e => e.name === formControl.value);

            if (formControl.value && _isInputValueInList) {
                if (_badgeDoesNotExist) {
                    // Behaviour: filter clear option in filter dropdown is clicked
                    this._createBadge(formControl, _autoSuggestionFieldInput, _autoSuggestionField).then(() => {
                        this._addCloseBtnEventListener(formControl, _currentItemId, _autoSuggestionField);
                    });
                } else {
                    // Behaviour: filter selected option in filter dropdown is clicked
                    this._addCloseBtnEventListener(formControl, _currentItemId, _autoSuggestionField);
                }
            } else if (_activeItemIds.length > 0) {
                // Behaviour: badges from parameters in url (active filter options) is created
                _activeItemIds.forEach((_activeItemId) => {
                    this._createBadge(formControl, _autoSuggestionFieldInput, _autoSuggestionField, _activeItemId).then(() => {
                        this._addCloseBtnEventListener(formControl, _activeItemId, _autoSuggestionField);
                    });
                })
            }

            this._updateSelectedItemsInFieldsAndComponents(_autoSuggestionField);
        }
    }

    /**
     * Add event listener for close button in badge
     *
     * @param {NucleusAInput|NucleusASelect} formControl
     * @param {String} _currentItemId
     * @param _autoSuggestionField
     * @private
     */
    _addCloseBtnEventListener(formControl, _currentItemId, _autoSuggestionField) {
        const _newBadge = this._wrapper.querySelector(`[data-item-id="${_currentItemId}"]`);
        const _closeButton = _newBadge.querySelector(".lirc-m-badge__close-icon");

        _closeButton.addEventListener("click", () => {
            let _filterName = formControl.rootElement.dataset.filterName;
            this._badges = this._badges.filter(e => e.itemId !== _currentItemId);
            this._updateSelectedItemsInFieldsAndComponents(_autoSuggestionField);
            this._deleteFilterValueFromUrl(_filterName, _currentItemId);
            this._updateFiltersAndSearchResults();
        })
    }

    /**
     * Updates the fields and components with the latest selected items
     *
     * @private
     */
    _updateSelectedItemsInFieldsAndComponents(_autoSuggestionField) {
        const _selectedItems = this._badges.map(e => e.itemId).toString();
        _autoSuggestionField.dataset.selectedItems = _selectedItems;
    }

    /**
     * Creates a badge for the given input.
     *
     * @param {NucleusAInput|NucleusASelect} formControl
     * @return {Promise<void>}
     * @private
     */
    async _createBadge(formControl, _autoSuggestionFieldInput, _autoSuggestionField = null, _activeItemId = null) {
        this._wrapper.rootElement.insertAdjacentHTML(
            "beforeend",
            await NunjucksWebWrapper.renderComponent(this._buildBadge(formControl, _autoSuggestionField, _activeItemId))
        );

        let badge = ComponentFactory.getInstance(
            this._wrapper.rootElement.querySelector(`[data-input="${formControl.rootId}"]`)
        );

        this._sortBadges();
        this.show();
        _autoSuggestionFieldInput.value = null;
    }

    /**
     * Creates a badge for the given form control.
     *
     * @param {NucleusAInput|NucleusASelect} formControl
     * @return {Object}
     * @private
     */
    _buildBadge(formControl, _autoSuggestionField = null, _activeItemId = null) {
        let _label;
        let _newBadge;
        let _itemId;

        if (null !== _autoSuggestionField && null !== _activeItemId) {
            let _fullList = JSON.parse(_autoSuggestionField.dataset.fullList);
            let _activeFilterOption = _fullList.find(e => e.id === _activeItemId);
            _label = _activeFilterOption.name;
            _itemId = _activeFilterOption.id;
        } else if (formControl instanceof NucleusAInput && (formControl.type === "checkbox" || formControl.type === "radio")) {
            _label = formControl.labelText;
            _itemId = formControl.getAttribute("data-current-item-id");
        } else {
            _label = formControl.value;
            _itemId = formControl.getAttribute("data-current-item-id");
        }

        _newBadge = {
            ...this._badgeTemplate,
            htmlAttributes: {
                class: "lirc-o-filter-badge-container__badge"
            },
            text: _label,
            itemId: _itemId,
            icon: formControl.getAttribute("data-icon"),
            objectButton: {
                component: "nucleus/a-button",
                htmlAttributes: {
                    "data-input": formControl.rootId
                }
            }
        };

        this._badges.push(_newBadge);
        return _newBadge;
    }

    /**
     * Sorts the badges alphabetically.
     *
     * @private
     */
    _sortBadges() {
        let badges = Array.from(this._wrapper.querySelectorAll(".lirc-o-filter-badge-container__badge"));
        let sorted = badges.sort((a, b) => a.innerText.localeCompare(b.innerText));
        sorted.forEach(e => this._wrapper.rootElement.appendChild(e));
    }

    /**
     * Clears the form control if the corresponding badge is clicked.
     *
     * @param {NucleusAInput|NucleusASelect} formControl
     * @private
     */
    _clearInput(formControl) {
        if (formControl instanceof NucleusAInput && (formControl.type === "checkbox" || formControl.type === "radio")) {
            formControl.checked = false;
        } else {
            formControl.value = null;
        }
    }

    /**
     * Deletes the badge for the given input.
     *
     * @param {NucleusAInput|NucleusASelect} formControl
     * @private
     */
    _deleteBadge(formControl) {
        const badge = this._wrapper.querySelector(`[data-input="${formControl.rootId}"]`);

        if (badge) {
            badge.remove();
        }

        if (this._wrapper.rootElement.children.length === 0) {
            this.hide();
        }
    }

    /**
     * Delete filter value from url
     *
     * @param {string} filterName
     * @param {string} filterValue
     * @private
     */
    _deleteFilterValueFromUrl(filterName, filterValue) {
        const url = new URL(window.location);
        const filtersParameter = "filters[" + filterName + "]";
        let _filterValues = url.searchParams.get(filtersParameter);
        if (url.searchParams.has("page")) {
            url.searchParams.delete("page");
        }

        if (_filterValues) {
            let _filterValueToRemove = filterValue;
            if (_filterValues.includes("," + filterValue)) _filterValueToRemove = "," + filterValue;
            if (_filterValues.includes(filterValue + ",")) _filterValueToRemove = filterValue + ",";
            let _updatedFilterValues = _filterValues.replace(_filterValueToRemove, '');

            if (_updatedFilterValues) {
                url.searchParams.set(filtersParameter, _updatedFilterValues);
            } else {
                url.searchParams.delete(filtersParameter);
            }
        } else {
            url.searchParams.delete(filtersParameter);
        }

        window.history.pushState({}, '', url);
    }

    /**
     * Delete filter value from url
     *
     * @param {string} filterName
     * @private
     */
    _deleteFilterFromUrl(filterName) {
        const url = new URL(window.location);
        const filtersParameter = "filters[" + filterName + "]";
        let _filterValues = url.searchParams.get(filtersParameter);

        if (url.searchParams.has("page")) {
            url.searchParams.delete("page");
        }

        url.searchParams.delete(filtersParameter);

        window.history.pushState({}, '', url);
    }

    /**
     * Delete all filters from url
     *
     * @private
     */
    _deleteFiltersFromUrl() {
        const _filters = [
            'courses',
            'diets',
            'foodTypes',
            'regions',
            'collections',
            'difficulties',
        ];

        _filters.forEach((_filterValue) => {
            this._deleteFilterFromUrl(_filterValue);
        });
    }

    /**
     * Update filter and search results
     *
     * @private
     */
    async _updateFiltersAndSearchResults() {
        const _recipeFiltersNSearchResultsContainer = document.getElementById("recipe-search__filters-n-search-results-container");
        const currentUrl = new URL(window.location);
        const updateUrl = new URL(currentUrl.origin + this._updateUrl);
        let _spinner = document.getElementById("loading-spinner");

        _spinner.classList.remove("loading-spinner--hidden");

        currentUrl.searchParams.forEach((filterValue, filterName) => {
            if (filterName !== "page") {
                updateUrl.searchParams.set(filterName, filterValue);
            }
        });

        try {
            const _url = new URL(updateUrl.href);
            let _response = await fetch(_url);
            _recipeFiltersNSearchResultsContainer.innerHTML = await _response.text();
            Factory.initAll(_recipeFiltersNSearchResultsContainer);
            this._initResetAllButton();

            const _filterToggle = document.querySelector(".lirc-m-dropdown-toggle");
            const _filterContainer = document.querySelector(".lirc-o-search-filter-container__filter-target");

            _filterToggle.addEventListener("click", () => {
                _filterContainer.classList.toggle("lirc-o-search-filter-container__filter-target--xs-hidden");
            });

        } catch (err) {
            console.error("Suggest fetch failed", err);
        }

        _spinner.classList.add("loading-spinner--hidden");

    }

    _resetAllFilters() {
        this._deleteFiltersFromUrl();
        this._updateFiltersAndSearchResults();
    }
}

ComponentFactory.registerComponent(LircOFilterBadgeContainer);
