import {Loader} from "nunjucks";

window.nunjucksPrecompiled = window.nunjucksPrecompiled || {};

export default class NucleusWebLoader extends Loader {

    constructor() {
        super();
        this.async = false;
        this.loaded = {};
    }

    /**
     * Set nunjucks core instance
     * @param {NunjucksCore} core
     */
    setCore(core) {
        this._core = core;
    }

    /**
     * Returns the requested template.
     * @param {String} name
     * @param {Function} callback
     * @return {*}
     */
    getSource(name, callback) {
        let path = name;
        path = "nunjucks/" + path.replace(".twig", ".njk.js");
        path = this._core.getAssetManifest("nucleus")[path];

        var result;
        this._fetch(path, function (error, src) {
            if (error) {
                if (callback) {
                    callback(error.content);
                } else if (error.status === 404) {
                    result = null;
                } else {
                    throw error.content;
                }
            } else {
                Function(src)();
                result = {
                    src: {
                        type: "code",
                        obj: window.nunjucksPrecompiled[name]
                    },
                    path: name
                };

                if (callback) {
                    callback(null, result);
                }
            }
        });

        return result;
    }

    /**
     * Fetches the requested url and calls the callback
     * @param {String} url
     * @param {Function} callback
     * @private
     */
    _fetch(url, callback) {
        const xhrRequest = new XMLHttpRequest();
        let loading = true;

        xhrRequest.onreadystatechange = function () {
            if (xhrRequest.readyState === XMLHttpRequest.DONE && loading) {
                loading = false;

                if (xhrRequest.status === 0 || xhrRequest.status === 200) {
                    callback(null, xhrRequest.responseText);
                } else {
                    callback({
                        status: xhrRequest.status,
                        content: xhrRequest.responseText
                    });
                }
            }
        };

        xhrRequest.open("GET", url, this.async);
        xhrRequest.send();
    }
}
