import nunjucks from "nunjucks";
import SchemaExtension from "./schema-extension.js";

/**
 * Keep this extension of nunjucks in sync with the Twig version of it in Nucleus\CoreBundle\Twig\CoreExtension.
 */
export default class CoreExtension {

    constructor(nunjucksWrapper) {
        this._nunjucksWrapper = nunjucksWrapper;

        nunjucksWrapper.environment.addFilter("get_modifier", (attributeValue, modifierName, optionsObject) => this.getModifier(attributeValue, modifierName, optionsObject));
        nunjucksWrapper.environment.addFilter("merge_data", CoreExtension.mergeData);
        nunjucksWrapper.environment.addFilter("merge_style_attributes", (data, cssPropertyName, cssPropertyValue, values) => this.mergeStyleAttributes(data, cssPropertyName, cssPropertyValue, values));
        nunjucksWrapper.environment.addFilter("render_attributes", (htmlAttributes, styleAttributes, extensions) => this.renderAttributes(htmlAttributes, styleAttributes, extensions));
        nunjucksWrapper.environment.addGlobal("render_attributes", (htmlAttributes, styleAttributes, extensions) => this.renderAttributes(htmlAttributes, styleAttributes, extensions));
    }

    /**
     * Returns the names of the breakpoints.
     * @returns {Object}
     * @private
     */
    _getBreakpointKeys() {
        let manifest = this._nunjucksWrapper.getNucleusManifest();
        return Object.keys(manifest.settings.BREAKPOINTS);
    }

    /**
     * Returns the starting breakpoint name.
     * @returns {string}
     * @private
     */
    _getStartingBreakpointKey() {
        return this._getBreakpointKeys()[0];
    }

    /**
     * Returns the "default" Value from the nucleus manifest of the component.
     * @param {String} component
     * @param {String} property
     * @returns {String}
     * @private
     */
    _getDefaultValue(component, property) {
        let componentParts = component.split("/");
        let namespace = componentParts[0];
        let defaultValue = this._nunjucksWrapper.getNucleusManifest().modules["@" + component].schema.properties[property].default;

        return defaultValue;
    }

    /**
     * Returns the modifier for the markup of the component.
     * @param {Boolean|Object|String} attributeValue
     * @param {String} modifierName
     * @param {Object} optionsObject
     * @param {Object} optionsObject["property"]
     * @param {Object} optionsObject["omitDefaultModifier"]
     * @param {Object} optionsObject["additionalCondition"]
     * @returns {String|Null|Array|*}
     */
    getModifier(attributeValue, modifierName, optionsObject) {
        if (typeof optionsObject !== "undefined") {
            if (typeof optionsObject["additionalCondition"] === "undefined") {
                optionsObject["additionalCondition"] = true;
            }
        } else {
            optionsObject = {};
            optionsObject["additionalCondition"] = true;
        }

        if (typeof optionsObject["omitDefaultModifier"] === "undefined") {
            optionsObject["omitDefaultModifier"] = false;
        }

        let defaultValue = null;

        if ((typeof optionsObject["property"] !== "undefined") && optionsObject["omitDefaultModifier"]) {
            let component = SchemaExtension.getCurrentComponentScope();
            defaultValue = this._getDefaultValue(component, optionsObject["property"]);
        }

        if (attributeValue && optionsObject["additionalCondition"]) {
            if (typeof attributeValue === "boolean") {
                if (attributeValue === true) {
                    return modifierName;
                }
            } else if (typeof attributeValue === "object") {
                let modifierClasses = [];
                let startingBreakpoint = this._getStartingBreakpointKey();

                for (let breakpoint in attributeValue) {
                    let modifierClassItem = modifierName + "-" + CoreExtension._formatModifierValue(attributeValue[breakpoint]);
                    let suffix = breakpoint !== startingBreakpoint ? "@" + breakpoint : "";

                    if (breakpoint !== startingBreakpoint || defaultValue !== attributeValue[breakpoint]) {
                        modifierClasses.push(modifierClassItem + suffix);
                    }
                }

                return modifierClasses;
            } else {
                if (defaultValue !== attributeValue) {
                    return modifierName + "-" + CoreExtension._formatModifierValue(attributeValue);
                }
            }
        }

        return null;
    }

    static mergeData(data, additionalData, override) {
        if (typeof override === "undefined") {
            override = true;
        }

        if (additionalData instanceof Object) {
            for (let key in additionalData) {
                if (additionalData.hasOwnProperty(key) && additionalData[key]) {
                    if (data[key]) {
                        if (data[key] instanceof Array && additionalData[key] instanceof Array) {
                            data[key] = data[key].concat(additionalData[key]);
                        } else if (data[key] instanceof Object && additionalData[key] instanceof Object) {
                            data[key] = CoreExtension.mergeData(data[key], additionalData[key], override);
                        } else if (key === "class" && typeof additionalData[key] === "string") {
                            data[key] = data[key].trim() + " " + additionalData[key].trim();
                        } else if (override) {
                            data[key] = additionalData[key];
                        }
                    } else {
                        data[key] = additionalData[key];
                    }
                }
            }
        }

        return data;
    }

    mergeStyleAttributes(data, cssPropertyName, cssPropertyValue, values) {
        if (!values) {
            return data;
        }
        let startingBreakpoint = this._getStartingBreakpointKey();

        if (!data["styleAttributes"]) {
            data["styleAttributes"] = {};
        } else if (!this._isResponsiveValue(data["styleAttributes"])) {
            data["styleAttributes"] = {
                [startingBreakpoint]: data["styleAttributes"]
            };
        }

        values = this._isResponsiveValue(values) ? values : { [startingBreakpoint]: values };

        for (const [breakpoint, value] of Object.entries(values)) {
            if (!data["styleAttributes"][breakpoint]) {
                data["styleAttributes"][breakpoint] = {};
            }

            data["styleAttributes"][breakpoint][cssPropertyName] = cssPropertyValue.replace("%value%", value).toString();
        }

        return data;
    }

    _isResponsiveValue(value) {
        return value instanceof Object &&
            (Object.keys(value).length === 0 || Object.keys(value).some(attribute => this._getBreakpointKeys().includes(attribute)));
    }

    static _formatModifierValue(value) {
        if (typeof value === "number" && isFinite(value) && Math.floor(value) === value) {
            if (value < 0) {
                return "negative-" + value;
            }
        }
        return value;
    }

    renderAttributes(htmlAttributes, styleAttributes, extensions) {
        let results = "";
        let classList = [];
        let escapeMap = {
            "\n": "&#xa;",
            "\t": "&#x9;",
            "\r": "&#xd;",
            "&": "&amp;",
            "\"": "&quot;",
            "<": "&lt;",
            ">": "&gt;"
        };

        if (htmlAttributes["class"]) {
            classList = htmlAttributes["class"].split(" ");
            delete htmlAttributes["class"];
        }

        if (htmlAttributes["classList"]) {
            classList = classList.concat(htmlAttributes["classList"]);
            delete htmlAttributes["classList"];
        }

        if (styleAttributes) {
            this._prepareStyleAttributes(htmlAttributes, styleAttributes);
        }

        if (extensions) {
            this._prepareExtensions(htmlAttributes, extensions);
        }

        for (let key in htmlAttributes) {
            key = key.replace(/([a-zA-Z])(?=[A-Z])/, "$1-").toLowerCase();
            if (key.startsWith("margin") || key.startsWith("padding")) {
                classList.push(this.getModifier(htmlAttributes[key], key));
            } else if (htmlAttributes[key] === true) {
                results += " " + key;
            } else if (htmlAttributes[key] || typeof htmlAttributes[key] === "number" || typeof htmlAttributes[key] === "string") {
                let value = htmlAttributes[key]
                    .toString()
                    .replace(
                        new RegExp(Object.keys(escapeMap).join("|"), "gi"),
                        matched => {
                            return escapeMap[matched];
                        }
                    );
                results += " " + key + "=\"" + value + "\"";
            }
        }

        if (classList.length > 0) {
            classList = classList.reduce((acc, val) => acc.concat(val), []);
            classList = classList.filter(item => item);
            results = "class=\"" + classList.join(" ") + "\"" + results;
        }

        return new nunjucks.runtime.SafeString(results);
    }

    _prepareStyleAttributes(htmlAttributes, styleAttributes) {
        let startingBreakpoint = this._getStartingBreakpointKey();
        let isResponsive = this._isResponsiveValue(styleAttributes);
        let attributesPerBreakpoint = isResponsive ? styleAttributes : {[startingBreakpoint]: styleAttributes};

        for (let breakpoint in attributesPerBreakpoint) {
            let styleAttribute = Object.entries(attributesPerBreakpoint[breakpoint]).map(([propertyName, propertyValue]) =>{
                return `${propertyName}:${propertyValue};`;
            }).join("").replace(/([a-zA-Z])(?=[A-Z])/, "$1-").toLowerCase();

            if (breakpoint === startingBreakpoint) {
                if (htmlAttributes["style"]) {
                    styleAttribute = htmlAttributes["style"] + styleAttribute;
                }

                htmlAttributes["style"] = styleAttribute;
            } else {
                htmlAttributes[`data-style-${breakpoint}`] = styleAttribute
            }
        }
    }

    _prepareExtensions(htmlAttributes, extensions) {
        for (let extension of extensions) {
            let attributeName = "data-" + extension["module"].replace("/", "-");
            htmlAttributes[attributeName] = JSON.stringify(extension);
        }
    }
}
