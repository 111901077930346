import "@nucleus/polyfill/src/_polyfill";
import { Component, ComponentFactory } from "@nucleus/core/src/_core";
import LidlRecipesAIngredientBoxItem from "@lidl-recipes/a-ingredient-box-item";
import ShoppingListService from "@lidl-recipes/global/lib/shopping-list-service";
import userService from "@lidl-recipes/global/lib/user-service";

export default class LidlRecipesOIngredientBox extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-ingredient-box";
    }

    /**
     * Creates an instance of LidlRecipesOIngredientBox.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._baseServingQuantity = rootElement.dataset.baseServingQuantity;
        this._textServingSingular = rootElement.dataset.servingSingular;
        this._textServingPlural = rootElement.dataset.servingPlural;
        this._textServingTypeId = rootElement.dataset.servingTypeId;
        this._servingAmount = this._baseServingQuantity;
        this._servingAmountContainer = rootElement.querySelector(".lirc-o-ingredient-box__serving-amount");
        this._servingText = rootElement.querySelector(".lirc-o-ingredient-box__serving-text");
        this._defaultShoppingListName = rootElement.dataset.defaultShoppingListName;
        this._gaFoodTypes = rootElement.dataset.gaFoodTypes;
        this._gaCourses = rootElement.dataset.gaCourses;
        this._gaCollections = rootElement.dataset.gaCollections;
        this._gaDiets = rootElement.dataset.gaDiets;
        this._gaRegions = rootElement.dataset.gaRegions;
        this._gaRecipeName = rootElement.dataset.gaRecipeName;

        /**
         * @type {LidlRecipesOOverlay}
         * @private
         */
        this._loginModal = ComponentFactory.getInstance(document.querySelector("#sso-login-modal"));

        /**
         * @type {LidlRecipesAIngredientBoxItem[]}
         * @private
         */
        this._ingredientItems = ComponentFactory.getInstances(
            rootElement.querySelectorAll(
                LidlRecipesAIngredientBoxItem.rootSelector
            )
        );

        /**
         * @type {NucleusMButton}
         * @private
         */
        this._increaseButton = ComponentFactory.getInstance(
            rootElement.querySelector(".lirc-o-ingredient-box__increase-button")
        );
        this._increaseButton.clickSubject.subscribe(() => {
            this._servingAmount++;
            this._servingAmountHandler();
            this._pushGaServingClick("ingredientcalculator_plusbutton");
        });

        /**
         * @type {NucleusMButton}
         * @private
         */
        this._decreaseButton = ComponentFactory.getInstance(
            rootElement.querySelector(".lirc-o-ingredient-box__decrease-button")
        );
        this._decreaseButton.clickSubject.subscribe(() => {
            this._servingAmount--;
            this._servingAmountHandler();
            this._pushGaServingClick("ingredientcalculator_minusbutton");
        });

        // Check to see if the shopping list exists
        const addToShoppingListButtonElement = rootElement.querySelector("#shopping-list-btn-add");

        if (addToShoppingListButtonElement) {
            this._shoppingListService = new ShoppingListService(rootElement.dataset.shoppingListApiUrl);

            /**
             * @type {NucleusMButton}
             * @private
             */
            this._addToShoppingListButton = ComponentFactory.getInstance(addToShoppingListButtonElement);
            this._addToShoppingListButton.clickSubject.subscribe(() => {
                if (!userService.isSignedIn) {
                    this._loginModal.open();
                    return;
                }
                this._addToShoppingListButton.disabled = true;
                this._trackAddToShoppingListClick();
                this._addItemsToShoppingList();
            });

            /**
             * @type {LidlRecipesOOverlay}
             * @private
             */
            this._shoppingListSuccessOverlay = ComponentFactory.getInstance(
                document.querySelector("#shopping-list-success-cta-modal")
            );
            this._shoppingListSuccessOverlay.visibilitySubject.subscribe((event, state) => {
                if (!state) {
                    // When the success overlay is closed, we want to ensure the shopping
                    // list button is re-enabled
                    this._addToShoppingListButton.disabled = false;
                } else {
                    this._addToShoppingListButton.disabled = true;
                }
            });

            /**
             * @type {NucleusMButton}
             * @private
             */
            this._shoppingListOverlayCloseButton = ComponentFactory.getInstance(
                this._shoppingListSuccessOverlay.rootElement.querySelector("#shopping-list-success-close")
            );
            this._shoppingListOverlayCloseButton.clickSubject.subscribe(() => {
                // Close the overlay when the "continue" button is pressed
                this._shoppingListSuccessOverlay.close();
            });
            
            /**
             * @type {NucleusMAlert}
             * @private
             */
            this._shoppingListErrorAlert = ComponentFactory.getInstance(
                rootElement.querySelector("#shopping-list-alert-failed")
            );
            this._shoppingListErrorAlert.visibilitySubject.subscribe((event, state) => {
                if (state) {
                    // When the error alert is visible, we want to hide the
                    // "add to shopping list" button
                    this._addToShoppingListButton.hide();
                } else {
                    // When the error alert is hidden, we want to show the
                    // "add to shopping list" button and enable it
                    this._addToShoppingListButton.show();
                    this._addToShoppingListButton.disabled = false;
                }
            });
    
            // Add scroll listener to the shopping list button so we can animate the shadow while it is scroll
            const stickyParent = rootElement.querySelector('.lirc-o-ingredient-box__ingredients');
            const sticky = rootElement.querySelector('.lirc-o-ingredient-box-shopping-list');
            let stickyHasShadow = false;
            const updateSticky = () => {
                // When the sticky element is at the bottom of the ingredient list we want to hide the shadow
                // but when it is floating above the ingredient list, we want a drop shadow
                const dist = stickyParent.getBoundingClientRect().bottom - sticky.getBoundingClientRect().bottom;
                if (stickyHasShadow && dist <= 0) {
                    sticky.classList.remove('lirc-o-ingredient-box-shopping-list_shadow');
                    stickyHasShadow = false;
                } else if (!stickyHasShadow && dist > 0) {
                    sticky.classList.add('lirc-o-ingredient-box-shopping-list_shadow');
                    stickyHasShadow = true;
                }
            };
            window.addEventListener('scroll', updateSticky);
            updateSticky();
        }
    }

    /**
     * Recalculates the serving text for the recipe and ingredients
     * 
     * @private
     * @function _servingAmountHandler
     */
    _servingAmountHandler() {
        this._servingText.innerText = this._servingAmount === 1 ? this._textServingSingular : this._textServingPlural;
        this._decreaseButton.disabled = this._servingAmount === 1;
        this._increaseButton.disabled = this._servingAmount >= 25;
        this._ingredientItems.forEach(item => item.servingAmount(this._servingAmount));
        this._servingAmountContainer.innerText = this._servingAmount;
    }

    /**
     * Logs a serving increase/decrease click
     * 
     * @private
     * @function _pushGaServingClick
     * @param {string} name The name of the event
     * @returns 
     */
    _pushGaServingClick(name) {
        if (!this._textServingTypeId) return;

        if (!window.dataLayer) {
            console.warn("[nucleus] GTM variable dataLayer is not available.");
            window.dataLayer = [];
        }

        window.dataLayer.push({
            event: "tap_item",
            itemName: name,
            contentType: this._textServingTypeId,
            quantity: this._servingAmount
        });
    }

    /**
     * Tracks that the shopping list "add" button was clicked
     *
     * @private
     * @function _trackAddToShoppingListClick
     */
    async _trackAddToShoppingListClick() {
        if (!window.dataLayer) {
            console.warn("[nucleus] GTM variable dataLayer is not available.");
            window.dataLayer = [];
        }

        window.dataLayer.push({
            "event": "add_to_shoppinglist",
            "recipe_name": this._gaRecipeName,
            "quantity": this._servingAmount,
            "user_id": await userService.userId,
            "recipe_food_type": this._gaFoodTypes || "",
            "recipe_course": this._gaCourses || "",
            "recipe_collection": this._gaCollections || "",
            "recipe_diet": this._gaDiets || "",
            "recipe_region": this._gaRegions || ""
        });
    }

    /**
     * Adds all items in this recipe to the users shopping list, assumes the user is authenticated.
     * 
     * @private
     * @async
     * @function _addItemsToShoppingList
     * @returns {Promive<void>}
     */
    async _addItemsToShoppingList() {
        try {
            const authToken = await userService.getAuthToken();
            this._shoppingListService.setAccessToken(authToken);
            let { id, eTag } = await this._getOrCreateShoppingListId();
            const ingredients = {};

            // get the existing list so we can update it with the new items
            const getListReponse = await this._shoppingListService.getList(id);
            if (getListReponse.ok) {
                // Copy out the exist data, if there is any
                if (getListReponse.data && getListReponse.data.items) {
                    getListReponse.data.items.forEach((item) => {
                        ingredients[item.id] = item;
                    });
                }
                // Copy the list eTag
                if (getListReponse.data.eTag) {
                    eTag = getListReponse.data.eTag;
                }
            }
            eTag = eTag.replace('\"', '"');

            // Avoid duplicates since each ingredient exists twice
            const processedIngredientItems = [];
            this._ingredientItems.forEach((ingredient) => {
                // Check if this ingredient is enabled for the shopping list
                if (ingredient.isSelected()) {
                    const item = ingredient.getShoppingListEntry();
                    if (processedIngredientItems.indexOf(item.id) < 0) {
                        // If this item already exists in the list, increase the quantity
                        if (ingredients[item.id] && !ingredients[item.id].isChecked) {
                            ingredients[item.id].quantity += item.quantity;
                        } else {
                            ingredients[item.id] = item;
                        }
                        processedIngredientItems.push(item.id);
                    }
                }
            });
            const requestBody = {
                items: Object.values(ingredients),
                list: {
                    itemIds: Object.keys(ingredients) 
                }
            };
            const updateListResponse = await this._shoppingListService.updateList(id, eTag, requestBody)
            if (updateListResponse.ok) {
                this._showFinishedAddingToShoppingList();
            } else {
                throw new Error(`Failed to update list ${updateListResponse.statusCode} ${updateListResponse.status}`);
            }
        } catch (err) {
            console.error('Error updating shopping list', err);
            this._showFailedToAddToShoppingList();
        }
    }

    /**
     * Gets the identifier of the users shopping list, or creates one if they dont have one.
     * Assumes the user is authenticated.
     * 
     * Note: currently the shopping list api only supports each user having a single shopping list
     * so we just grab their first (and presumably only) shopping list and we use that.
     * 
     * @private
     * @async
     * @function _getOrCreateShoppingListId
     * @returns {Promise<List>} Identifier of the users shopping list
     * @throws {Error} If we cannot create or get the shopping list
     */
    async _getOrCreateShoppingListId() {
        // The API doesn't correctly expose it's headers
        // The API docs describe that when we create a list, we get the eTag
        // but they don't properly expose the headers using CORS so the 
        // workaround is to create the list and then get it, since that
        // provides the necessary eTag in the response data
        const list = await this._getShoppingList();
        if (list === null) {
            await this._createList(this._defaultShoppingListName);
            return this._getShoppingList();
        }
        return list;
    }

    /**
     * Creates a shopping list with the provided name
     * 
     * @private
     * @async
     * @function _createList
     * @param {string} name the name of the list to create
     * @returns {{ id: string, eTag?: string } | null}
     */
    async _createList(name) {
        const response = await this._shoppingListService.createList(name);
        return response.data && response.data.id ? { id: response.data.id, eTag: response.data.eTag } : null;
    }

    /**
     * Gets the users default shopping list
     * 
     * @private
     * @async
     * @function _getShoppingList
     * @returns {{ id: string, eTag: string } | null}
     */
    async _getShoppingList() {
        const response = await this._shoppingListService.getLists();
        if (response.data && response.data.lists && response.data.lists.length > 0) {
            // First try and find a list named the same as our default list
            for (let i = 0; i < response.data.lists.length; ++i) {
                if (response.data.lists[i].name === this._defaultShoppingListName) {
                    return response.data.lists[i];
                }
            }
            // Otherwise return their first list
            return response.data.lists[0];
        }

        // We failed to find a list
        return null;
    }

    /**
     * Notifies the user that we failed to add the ingredients to their shopping list
     * 
     * @private
     * @function _showFailedToAddToShoppingList
     */
    _showFailedToAddToShoppingList() {
        this._shoppingListErrorAlert.show();
    }

    /**
     * Notifies the user that we successfully added the ingredients to their shopping list
     * 
     * @private
     * @function
     */
    _showFinishedAddingToShoppingList() {
        this._shoppingListSuccessOverlay.open();
    }
}

ComponentFactory.registerComponent(LidlRecipesOIngredientBox);
