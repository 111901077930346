import "@nucleus/polyfill/src/_polyfill";
import {Component, ComponentFactory, Factory} from "@nucleus/core/src/_core";
import { BREAKPOINTS } from "@nucleus/core/src/_constants";
import oLanguageNavContainer from "@nucleus/o-language-nav-container";
import NucleusAAnchor from "@nucleus/a-anchor";
import HtmlUtils from "@nucleus/core/src/_htmlUtils";
import { identifyPage } from "@lidl-recipes/global/src/scripts/utils";
import userService from "@lidl-recipes/global/lib/user-service";

export default class LidlRecipesOHeader extends Component {
    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-header";
    }

    /**
     * Creates an instance of LidlRecipesOHeader.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        // Workaround: Enable SSO features for signed-in users only.
        if (userService.isSignedIn) {
            document.querySelector("html").classList.remove("signed-out-user");
        }

        this._addSelectors({
            content: ".lirc-o-header__content",
            brandNavigation: ".lirc-o-header__brand-navigation",
            languageNavigation: ".lirc-o-header__language-navigation",
            hamburgerNavigation: ".lirc-o-header__hamburger-navigation",
            navigation: {
                root: ".lirc-o-header__navigation",
                backBtn: ".lirc-o-header__navigation-back",
                main: ".lirc-o-header__navigation-main",
                sub: ".lirc-o-header__navigation-sub",
                navItemsWithSubNav:
                    "[data-js-subnav]:not(.nuc-m-header-main-nav-item--direct-access)",
                anchor: "a[class$='anchor']",
                activeItem: "li[class$='--active']",
                dropdownItem: "li[class$='--dropdown']"
            }
        });

        this._addModifiers({
            root: {
                mobile: "lirc-o-header--mobile"
            },
            content: {
                subActive: "lirc-o-header__content--sub-active"
            },
            hamburgerNavigation: {
                active: "lirc-o-header__hamburger-navigation--active"
            },
            navigation: {
                item: {
                    active: "--active"
                },
                subNavs: {
                    hidden: "hidden@all"
                },
                slideFromRight: "lirc-o-header__navigation--from-right",
                slideToRight: "lirc-o-header__navigation--to-right",
                slideFromLeft: "lirc-o-header__navigation--from-left",
                slideToLeft: "lirc-o-header__navigation--to-left"
            }
        });

        this._addAttributes({
            brandNavigation: {
                tabindex: "tabindex"
            },
            languageNavigation: {
                tabindex: "tabindex"
            },
            navigation: {
                root: {
                    ariaExpanded: "aria-expanded"
                },
                backBtn: {
                    tabindex: "tabindex"
                },
                main: {
                    class: "class",
                    tabindex: "tabindex"
                },
                subNav: {
                    class: "class"
                },
                dataJsSubNav: "data-js-subnav",
                prevMenu: {
                    class: "class",
                    tabindex: "tabindex"
                },
                activeMenu: {
                    class: "class",
                    tabindex: "tabindex"
                }
            }
        });

        this._addElements({
            content: rootElement.querySelector(this._selectors.content),
            brandNavigation: rootElement.querySelector(
                this._selectors.brandNavigation
            ),
            languageNavigation: rootElement.querySelector(
                this._selectors.languageNavigation
            ),
            hamburgerNavigation: rootElement.querySelector(
                this._selectors.hamburgerNavigation
            ),
            navigation: {
                root: rootElement.querySelector(
                    this._selectors.navigation.root
                ),
                backBtn: rootElement.querySelector(
                    this._selectors.navigation.backBtn
                ),
                main: rootElement.querySelector(
                    this._selectors.navigation.main
                ),
                sub: rootElement.querySelector(this._selectors.navigation.sub),
                subNavs: rootElement.querySelectorAll(
                    this._selectors.navigation.sub
                ),
                navItemsWithSubNav: rootElement.querySelectorAll(
                    this._selectors.navigation.navItemsWithSubNav
                )
            }
        });

        this._mobileBreakpoint = BREAKPOINTS.md;
        this._isMobile = window.innerWidth < this._mobileBreakpoint;
        this._prevMenus = [];
        this._activeMenu = null;
        this._initialWidth = window.innerWidth;
        this._closeMenuTimeout = null;

        this._tabindexes = {
            override: -1,
            brandNavigation: {
                xs: 9,
                md: 1
            },
            languageNavigation: {
                inline: {
                    xs: 8,
                    md: 2
                },
                dropdown: {
                    xs: 8,
                    md: 1
                }
            },
            navigation: {
                main: 7,
                backBtn: 6,
                prevMenu: 7,
                activeMenu: 7
            }
        };

        if (
            this._elements.hamburgerNavigation &&
            this._elements.navigation.root
        ) {
            this._elements.hamburgerNavigation.addEventListener("click", () =>
                this._handleHamburgerClick()
            );
            this._elements.navigation.backBtn.addEventListener("click", () =>
                this._handleNavBackBtnClick()
            );
            this._elements.navigation.navItemsWithSubNav.forEach((navItem, i) =>
                navItem.addEventListener("click", e =>
                    this._handleNavItemWithSubNavClick(e, i)
                )
            );
            window.addEventListener("resize", () => this._handleResize());

            this._elements.navigation.main
                .querySelectorAll(this._selectors.navigation.anchor)
                .forEach(anchor =>
                    anchor.setAttribute(
                        this._attributes.navigation.main.tabindex,
                        this._tabindexes.navigation.main
                    )
                );

            this._updateTabIndexes();
        }

        if (
            this._elements.navigation.root !== null &&
            this._elements.navigation.root.hasAttribute("data-subnav-hover")
        ) {
            this._setupHoverTrigger();
        }

        /**
         * WIP: A more generic tracking handling needs to be implemented at some point.
         */
        this._userNavItem = document.querySelector("[data-ga-id]");
        if(!this._userNavItem) return;

        /**
         * @type {NucleusAAnchor}
         * @private
         */
        this._favoriteItemAnchor = ComponentFactory.getInstance(this._userNavItem.querySelector(NucleusAAnchor.rootSelector));
        this._favoriteItemAnchor.clickSubject.subscribe(() => {
            this._pushGaClick("favorites");
        })
    }

    /**
     * @private
     */
    _pushGaClick(item) {
        if (!window.dataLayer) {
            console.warn("[nucleus] GTM variable dataLayer is not available.");
            window.dataLayer = [];
        }

        window.dataLayer.push({
            "event": "navigation",
            "nav_name": item,
            "screen_name": identifyPage(),
        });
    }

    /**
     * Set up hover triggers in hover is enabled
     * @private
     */
    _setupHoverTrigger() {
        this._elements.navigation.navItemsWithSubNav.forEach((navItem, i) => {
            navItem.addEventListener("mouseover", e => {
                if (!this._isMobile) {
                    this._handleNavItemWithSubNavHover(e, i);
                    clearTimeout(this._closeMenuTimeout);
                }
            });
        });

        let navItemsWithoutSubNav = this._rootElement.querySelectorAll(
            "li[class$='item']:not([data-js-subnav])"
        );
        navItemsWithoutSubNav.forEach(navItem => {
            navItem.addEventListener("mouseover", e => {
                if (!this._isMobile) {
                    this._resetMainAndSubNavsHoverStates(e);
                    clearTimeout(this._closeMenuTimeout);
                }
            });
        });

        let subSlidersContent = this._rootElement.querySelectorAll(
            ".lirc-o-header-nav-slider__content"
        );
        subSlidersContent.forEach(subSliderContent => {
            subSliderContent.addEventListener("mouseover", e => {
                if (
                    !this._isMobile &&
                    e.target.classList.contains(
                        "lirc-o-header-nav-slider__content"
                    )
                ) {
                    this._resetMainAndSubNavsHoverStates(e);
                }

                if (
                    !this._isMobile &&
                    e.target.parentElement.classList.contains(
                        "nuc-m-header-main-nav-item"
                    )
                ) {
                    let hoveredElements = this.rootElement.querySelectorAll(
                        ".nuc-m-header-main-nav-item--active-hover"
                    );

                    hoveredElements.forEach(hoveredElement =>
                        hoveredElement.classList.remove(
                            "nuc-m-header-main-nav-item--active-hover"
                        )
                    );

                    if (
                        !e.target.parentElement.hasAttribute("data-js-subnav")
                    ) {
                        this._resetMainAndSubNavs();
                    }

                    e.target.parentElement.classList.add(
                        "nuc-m-header-main-nav-item--active-hover"
                    );
                }

                if (
                    !this._isMobile &&
                    e.target.parentElement.classList.contains(
                        "nuc-m-header-sub-nav-item"
                    )
                ) {
                    let hoveredSubElements = e.target.parentElement.parentElement.querySelectorAll(
                        ".nuc-m-header-sub-nav-item--active-hover"
                    );

                    hoveredSubElements.forEach(hoveredSubElement =>
                        hoveredSubElement.classList.remove(
                            "nuc-m-header-sub-nav-item--active-hover"
                        )
                    );

                    e.target.parentElement.classList.add(
                        "nuc-m-header-sub-nav-item--active-hover"
                    );
                }
            });
        });

        let mainAndSubNav = this._rootElement.querySelector(
            ".lirc-o-header__navigation"
        );
        mainAndSubNav.addEventListener("mouseleave", () => {
            if (!this._isMobile) {
                let subNavItemsActiveHover = mainAndSubNav.querySelectorAll(
                    ".nuc-m-header-sub-nav-item--active-hover"
                );
                subNavItemsActiveHover.forEach(item =>
                    item.classList.remove(
                        "nuc-m-header-sub-nav-item--active-hover"
                    )
                );

                let mainNavItemsActiveHover = mainAndSubNav.querySelectorAll(
                    ".nuc-m-header-main-nav-item--active-hover"
                );
                mainNavItemsActiveHover.forEach(item =>
                    item.classList.remove(
                        "nuc-m-header-main-nav-item--active-hover"
                    )
                );

                let sliderBackBtns = this._rootElement.querySelectorAll(
                    ".lirc-o-header-nav-slider__btn-left"
                );
                sliderBackBtns.forEach(sliderBackBtn => {
                    if (!sliderBackBtn.classList.contains("hidden@all")) {
                        sliderBackBtn.click();
                    }
                });

                this._closeMenuTimeout = setTimeout(() => {
                    this._resetMainAndSubNavs();
                }, 750);
            }
        });
    }

    /**
     * Updates tabindexes
     * @private
     */
    _updateTabIndexes() {
        if (this._elements.brandNavigation) {
            this._elements.brandNavigation
                .querySelectorAll(this._selectors.navigation.anchor)
                .forEach(anchor =>
                    anchor.setAttribute(
                        this._attributes.brandNavigation.tabindex,
                        this._isMobile
                            ? this._tabindexes.brandNavigation.xs
                            : this._tabindexes.brandNavigation.md
                    )
                );
        }

        if (this._elements.languageNavigation) {
            if (
                this._elements.languageNavigation.querySelector(
                    this._selectors.navigation.dropdownItem
                ) !== null
            ) {
                let dropdownButton = Factory.createComponent(
                    this._elements.languageNavigation.querySelector(
                        "." + oLanguageNavContainer.rootClassName
                    )
                );
                this._elements.languageNavigation
                    .querySelector(dropdownButton.dropdownTriggerSelector)
                    .setAttribute(
                        this._attributes.languageNavigation.tabindex,
                        this._isMobile
                            ? this._tabindexes.languageNavigation.dropdown.xs
                            : this._tabindexes.languageNavigation.dropdown.md
                    );
                this._elements.languageNavigation
                    .querySelectorAll(this._selectors.navigation.anchor)
                    .forEach(anchor =>
                        anchor.setAttribute(
                            this._attributes.languageNavigation.tabindex,
                            this._isMobile
                                ? this._tabindexes.languageNavigation.dropdown
                                      .xs
                                : this._tabindexes.languageNavigation.dropdown
                                      .md
                        )
                    );
            } else {
                this._elements.languageNavigation
                    .querySelectorAll(this._selectors.navigation.anchor)
                    .forEach(anchor =>
                        anchor.setAttribute(
                            this._attributes.languageNavigation.tabindex,
                            this._isMobile
                                ? this._tabindexes.languageNavigation.inline.xs
                                : this._tabindexes.languageNavigation.inline.md
                        )
                    );
            }
        }
    }

    /**
     * Handles resize of browser
     * @private
     */
    _handleResize() {
        var widthAfterResize = window.innerWidth;
        this._isMobile = widthAfterResize < this._mobileBreakpoint;

        this._prevMenus.forEach((prevMenu, i) =>
            i > 0 ? Factory.createComponent(prevMenu).show() : null
        );

        if (
            this._initialWidth < widthAfterResize &&
            this._initialWidth < this._mobileBreakpoint &&
            !this._isMobile
        ) {
            // expand from mobile to desktop
            this._elements.hamburgerNavigation.classList.remove(
                this._modifiers.hamburgerNavigation.active
            );
            this._elements.root.classList.remove(this._modifiers.root.mobile);
            this._elements.navigation.root.setAttribute(
                this._attributes.navigation.root.ariaExpanded,
                "false"
            );
            if (this._activeMenu) {
                Factory.createComponent(this._activeMenu).show();
            }
            this._resetMainAndSubNavs();
            this._elements.navigation.main
                .querySelectorAll(this._selectors.navigation.anchor)
                .forEach(anchor =>
                    anchor.setAttribute(
                        this._attributes.navigation.main.tabindex,
                        this._tabindexes.navigation.main
                    )
                );
        } else if (
            this._initialWidth > widthAfterResize &&
            this._initialWidth >= this._mobileBreakpoint &&
            this._isMobile
        ) {
            // shrink from desktop to mobile
            this._elements.navigation.subNavs.forEach(subNav =>
                Factory.createComponent(subNav).show()
            );
            this._resetMainAndSubNavs();
        }

        if (this._activeMenu && this._isMobile) {
            this._elements.navigation.root.style.height =
                this._activeMenu.offsetHeight + "px";
        } else {
            this._elements.navigation.root.style.height = "auto";
        }

        this._initialWidth = widthAfterResize;
    }

    /**
     * Handles click on hamburger button
     * @private
     */
    _handleHamburgerClick() {
        // Immediately trigger click on active main nav item to achieve desired initial menu state.
        let isSubActive = this.rootElement.querySelector(".lirc-o-header__content--sub-active");
        let activateMainNavItem = this.rootElement.querySelector(".lirc-o-header__navigation-main .nuc-m-header-main-nav-item--active .nuc-m-header-main-nav-item__anchor");
        if (!isSubActive) activateMainNavItem.click();

        this._elements.hamburgerNavigation.classList.toggle(
            this._modifiers.hamburgerNavigation.active
        );
        this._elements.root.classList.toggle(this._modifiers.root.mobile);

        if (this._activeMenu) {
            this._elements.navigation.root.style.height =
                this._activeMenu.offsetHeight + "px";
        }

        var ariaIsExpanded =
            this._elements.navigation.root.getAttribute(
                this._attributes.navigation.root.ariaExpanded
            ) === "true";
        this._elements.navigation.root.setAttribute(
            this._attributes.navigation.root.ariaExpanded,
            !ariaIsExpanded
        );

        if (ariaIsExpanded) {
            this._resetMainAndSubNavs();
        }

        this._handleResize();
    }

    /**
     * Handles click on hamburger menu back button
     * @private
     */
    _handleNavBackBtnClick() {
        var prevMenu = this._prevMenus[this._prevMenus.length - 1];
        prevMenu.setAttribute(
            this._attributes.navigation.prevMenu.class,
            [
                prevMenu.classList[0],
                this._modifiers.navigation.slideFromLeft
            ].join(" ")
        );
        this._activeMenu.setAttribute(
            this._attributes.navigation.activeMenu.class,
            [
                this._activeMenu.classList[0],
                this._modifiers.navigation.slideToRight
            ].join(" ")
        );
        prevMenu
            .querySelectorAll(this._selectors.navigation.anchor)
            .forEach(anchor =>
                anchor.setAttribute(
                    this._attributes.navigation.prevMenu.tabindex,
                    this._tabindexes.navigation.prevMenu
                )
            );
        this._activeMenu
            .querySelectorAll(this._selectors.navigation.anchor)
            .forEach(anchor =>
                anchor.setAttribute(
                    this._attributes.navigation.activeMenu.tabindex,
                    this._tabindexes.override
                )
            );

        this._prevMenus.pop();

        if (this._prevMenus.length == 0) {
            this._elements.content.classList.remove(
                this._modifiers.content.subActive
            );
            this._elements.navigation.backBtn.setAttribute(
                this._attributes.navigation.backBtn.tabindex,
                this._tabindexes.override
            );
            this._elements.navigation.root.style.height = "auto";
            this._elements.hamburgerNavigation.focus();
        } else {
            this._elements.navigation.root.style.height =
                prevMenu.offsetHeight + "px";
        }

        setTimeout(() => {
            this._activeMenu.classList.remove(
                this._modifiers.navigation.slideToRight
            );
            Factory.createComponent(this._activeMenu).show();
            this._activeMenu = prevMenu;
        }, 250);
    }

    /**
     * Gets the navigation the trigger target sit's in
     * @param {Element} triggerTarget
     * @return {Element}
     */
    _getParentNav(triggerTarget) {
        return (
            HtmlUtils.ancestorHasClass(
                triggerTarget,
                HtmlUtils.cssSelectorToClass(this._selectors.navigation.sub)
            ) ||
            HtmlUtils.ancestorHasClass(
                triggerTarget,
                HtmlUtils.cssSelectorToClass(this._selectors.navigation.main)
            )
        );
    }

    /**
     * Gets the active subnavigation
     * @param {Number} subNavIndex
     * @return {Element}
     */
    _getActiveMenu(subNavIndex) {
        return this._elements.navigation.subNavs[subNavIndex];
    }

    /**
     * Checks if the trigger target sit's in a subnavigation
     * @param {Element} triggerTarget
     * @return {Element|null}
     */
    _isSubNav(triggerTarget) {
        return HtmlUtils.ancestorHasClass(
            triggerTarget,
            HtmlUtils.cssSelectorToClass(this._selectors.navigation.sub)
        );
    }

    /**
     * Checks if the trigger target has a subnavigation
     * @param {Element} triggerTarget
     * @return {Element|null}
     */
    _hasSubNav(triggerTarget) {
        return HtmlUtils.ancestorHasAttr(
            triggerTarget,
            this._attributes.navigation.dataJsSubNav
        );
    }

    /**
     * Handles click on navitem with subnav
     * @param {Event} e
     * @param {Number} i
     * @private
     */
    _handleNavItemWithSubNavClick(e, i) {
        this._updateMainAndSubNavs(e, i);
        this._elements.content.classList.add(this._modifiers.content.subActive);
        this._dispatchResize();
    }

    /**
     * Handles hover on navitem with subnav
     * @param {Event} e
     * @param {Number} i
     * @private
     */
    _handleNavItemWithSubNavHover(e, i) {
        let targetClassList = e.currentTarget.classList;

        if (
            targetClassList.contains("nuc-m-header-main-nav-item") &&
            !targetClassList.contains("nuc-m-header-main-nav-item--active")
        ) {
            e.currentTarget.classList.add(
                "nuc-m-header-main-nav-item--active-hover"
            );
        } else if (
            targetClassList.contains("nuc-m-header-sub-nav-item") &&
            !targetClassList.contains("nuc-m-header-sub-nav-item--active")
        ) {
            e.currentTarget.classList.add(
                "nuc-m-header-sub-nav-item--active-hover"
            );
        }

        this._resetMainAndSubNavs(e);
        this._updateMainAndSubNavs(e, i);

        this._elements.content.classList.add(
            "lirc-o-header__content--sub-active-hover"
        );

        if (!this._isMobile) {
            this._elements.navigation.main.style.height = "auto";
        } else {
            this._prevMenus
                .querySelectorAll(this._selectors.navigation.anchor)
                .forEach(item =>
                    item.setAttribute(
                        this._attributes.navigation.prevMenu.tabindex,
                        this._tabindexes.override
                    )
                );
            this._elements.navigation.root.style.height =
                activeMenu.offsetHeight + "px";
        }
    }

    /**
     * Returns sibling element matching selector
     * @param {Element} elem
     * @param {String} selector
     * @private
     */
    _getNextSibling(elem, selector) {
        var sibling = elem.nextElementSibling;

        if (!selector) return sibling;

        while (sibling) {
            if (sibling.matches(selector)) return sibling;
            sibling = sibling.nextElementSibling;
        }
    }

    /**
     * Updates main-navigation and sub-navigations
     * @private
     */
    _updateMainAndSubNavs(e, i) {
        let triggerTarget = e.target;
        let hasSubNav = this._hasSubNav(triggerTarget);

        if (e.type === "mouseover" && this._isMobile && hasSubNav) {
            return;
        }

        if (e.type === "click" && !this._isMobile && hasSubNav) {
            return;
        }

        let parentNav = this._getParentNav(triggerTarget);
        let activeMenu = this._getActiveMenu(i);
        let isSubNav = this._isSubNav(triggerTarget);

        if (
            this._isMobile &&
            hasSubNav &&
            (triggerTarget.classList.contains(
                "nuc-m-header-main-nav-item__anchor"
            ) ||
                triggerTarget.classList.contains(
                    "nuc-m-header-sub-nav-item__anchor"
                ))
        ) {
            e.preventDefault();
            parentNav.setAttribute(
                this._attributes.navigation.prevMenu.class,
                parentNav.classList[0] +
                    " " +
                    this._modifiers.navigation.slideToLeft
            );
        }

        if (!isSubNav) {
            this._prevMenus = [];

            if (!this._isMobile) {
                this._elements.navigation.subNavs.forEach(subNav =>
                    Factory.createComponent(subNav).hide()
                );
            }
        }

        let navItemContainer = HtmlUtils.ancestorHasClass(
            e.currentTarget,
            "lirc-o-header__navigation-sub"
        );

        if (navItemContainer) {
            let siblingWithClass = this._getNextSibling(
                navItemContainer,
                ".lirc-o-header__navigation--from-right"
            );

            if (siblingWithClass) {
                siblingWithClass.setAttribute(
                    "class",
                    "lirc-o-header__navigation-sub hidden@all"
                );
            }
        }

        this._elements.navigation.backBtn.setAttribute(
            this._attributes.navigation.backBtn.tabindex,
            this._tabindexes.navigation.backBtn
        );
        activeMenu
            .querySelectorAll(this._selectors.navigation.anchor)
            .forEach(anchor =>
                anchor.setAttribute(
                    this._attributes.navigation.activeMenu.tabindex,
                    this._tabindexes.navigation.activeMenu
                )
            );
        activeMenu.setAttribute(
            "class",
            "lirc-o-header__navigation-sub lirc-o-header__navigation--from-right"
        );

        this._resetSiblingNavs(activeMenu);

        this._prevMenus.push(parentNav);

        if (
            this._activeMenu &&
            this._activeMenu.classList.contains("lirc-o-header__navigation-main")
        ) {
            this._activeMenu.setAttribute(
                "class",
                "lirc-o-header__navigation-main lirc-o-header__navigation--to-left"
            );
        } else if (this._activeMenu) {
            this._activeMenu.setAttribute(
                "class",
                "lirc-o-header__navigation-sub lirc-o-header__navigation--to-left"
            );
        }

        this._activeMenu = activeMenu;
    }

    /**
     * Resets main-navigation and sub-navigations
     * @private
     */
    _resetMainAndSubNavs(e) {
        let isSubNav = false;

        if (e) {
            let triggerTarget = e.target;
            isSubNav = this._isSubNav(triggerTarget);
        }

        this._elements.navigation.subNavs.forEach(subNav => {
            if (subNav.hasAttribute("data-subnav-show") && !this._isMobile) {
                subNav.setAttribute("class", "lirc-o-header__navigation-sub");
            } else if (!isSubNav) {
                subNav.setAttribute(
                    "class",
                    "lirc-o-header__navigation-sub hidden@all"
                );
            }

            let activeItem = subNav.querySelector(
                ".nuc-m-header-sub-nav-item--active-hover"
            );
            if (activeItem) {
                activeItem.classList.remove(
                    "nuc-m-header-sub-nav-item--active-hover"
                );
            }
        });

        this._elements.navigation.main.setAttribute(
            this._attributes.navigation.main.class,
            this._selectors.navigation.main.slice(1)
        );
        this._elements.navigation.navItemsWithSubNav.forEach(navItem => {
            navItem.querySelector(this._selectors.navigation.anchor).blur();
        });
        this._prevMenus = [];
        this._activeMenu = null;
        this._elements.content.classList.remove(
            this._modifiers.content.subActive
        );
        this._elements.navigation.backBtn.setAttribute(
            this._attributes.navigation.backBtn.tabindex,
            this._tabindexes.override
        );
        this._elements.navigation.backBtn.blur();
        this._updateTabIndexes();
    }

    /**
     * Resets hover states on main- and subnavs
     * @private
     */
    _resetMainAndSubNavsHoverStates(e) {
        let hoveredNav =
            HtmlUtils.ancestorHasClass(
                e.target,
                "lirc-o-header__navigation-main"
            ) ||
            HtmlUtils.ancestorHasClass(
                e.target,
                "lirc-o-header__navigation-sub"
            );

        if (hoveredNav) {
            let activeHoverItemMain = hoveredNav.querySelector(
                ".nuc-m-header-main-nav-item--active-hover"
            );
            if (activeHoverItemMain) {
                activeHoverItemMain.classList.remove(
                    "nuc-m-header-main-nav-item--active-hover"
                );
            }

            let activeHoverItemSub = hoveredNav.querySelector(
                ".nuc-m-header-sub-nav-item--active-hover"
            );
            if (activeHoverItemSub) {
                activeHoverItemSub.classList.remove(
                    "nuc-m-header-sub-nav-item--active-hover"
                );
            }

            this._resetSiblingNavs(hoveredNav);
        }
    }

    /**
     * Resets hsibling navigations
     * @private
     */
    _resetSiblingNavs(nav) {
        let siblingNavs = [];
        let siblingNav = nav.nextElementSibling;

        for (; siblingNav; siblingNav = siblingNav.nextSibling) {
            if (siblingNav.nodeType == 1) {
                siblingNavs.push(siblingNav);
            }
        }

        siblingNavs.forEach(siblingNav => {
            siblingNav.setAttribute(
                "class",
                "lirc-o-header__navigation-sub hidden@all"
            );
        });
    }

    /**
     * Dispatches resize-event for modern browsers and IE
     * @private
     */
    _dispatchResize() {
        if (typeof Event === "function") {
            window.dispatchEvent(new Event("resize"));
        } else {
            var e = window.document.createEvent("UIEvents");
            e.initUIEvent("resize", true, false, window, 0);
            window.dispatchEvent(e);
        }
    }
}

Factory.registerComponent(LidlRecipesOHeader);
