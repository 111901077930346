import "@nucleus/polyfill/src/_polyfill";
import {Component, Factory} from "@nucleus/core/src/_core";
import {i18n} from "@nucleus/core/src/_i18n";
import NucleusAInput from "@nucleus/a-input";
import LidlRecipesOSearchDropdown from "@lidl-recipes/o-search-dropdown";
import NucleusOCheckboxGroup from "@nucleus/o-checkbox-group";

export default class LidlRecipesOSearchDropdownCheckbox extends LidlRecipesOSearchDropdown {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-search-dropdown-checkbox";
    }

    /**
     * Creates an instance of LidlRecipesOSearchDropdownCheckbox.
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        /**
         * @type {NucleusAInput[]}
         * @private
         */
        this._inputs = Factory.createComponents(rootElement.querySelectorAll(NucleusAInput.rootSelector));

        /**
         * @type {NucleusOCheckboxGroup[]}
         * @private
         */
        this._inputGroups = Factory.createComponents(rootElement.querySelectorAll(NucleusOCheckboxGroup.rootSelector));

        /**
         * @type {string}
         * @private
         */
        this._textInputSelected = this.rootElement.dataset.textInputSelected;
    }

    /**
     * @inheritDoc
     */
    _getButtonTextToSelection() {
        if (typeof this._textInputSelected === "undefined") {
            super._getButtonTextToSelection();
            return;
        }

        let selectionText;
        let selectedInputs = this._inputs.filter(input => input.checked);
        let selectedInputsLength = selectedInputs.length;

        if (this._inputGroups.length > 0) {
            let selectedGroupInputsLength = this._inputGroups.filter(inputGroup => inputGroup.checked).length;
            selectedInputsLength -= selectedGroupInputsLength;
        }

        if (selectedInputsLength > 0) {
            selectionText = i18n.pluralize(this._textInputSelected, {num: selectedInputsLength}, selectedInputsLength);
        }

        return selectionText;
    }
}

Factory.registerComponent(LidlRecipesOSearchDropdownCheckbox);
