/**
 * Keep this extension of nunjucks in sync with the Twig version of it in Nucleus\CoreBundle\Twig\DebugExtension.
 */
export default class DebugExtension {

    constructor(nunjucksWrapper) {
        this._nunjucksWrapper = nunjucksWrapper;

        nunjucksWrapper.environment.addGlobal("throw_exception", DebugExtension.throwException);
        nunjucksWrapper.environment.addGlobal("get_stack_trace", DebugExtension.getStackTrace);
    }

    static throwException(value) {
        console.error(value);
    }

    static getStackTrace() {
        console.trace();
        return [];
    }
}
