import "@nucleus/polyfill/src/_polyfill";
import { Component, ComponentFactory, Factory } from "@nucleus/core/src/_core";
import { NunjucksWebWrapper } from "@nucleus-tools/nunjucks-web";
import EventUtils from "@nucleus/core/src/_eventUtils";
import LidlRecipesMFavoriteButton from "@lidl-recipes/m-favorite-button";
import userService from "@lidl-recipes/global/lib/user-service";

export default class LidlRecipesOFavoriteList extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-favorite-list";
    }

    /**
     * Creates an instance of LidlRecipesOFavoriteList.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._textMinutes = this.rootElement.dataset.minutes;
        this._urlParams = new URLSearchParams();
        this._textHours = this.rootElement.dataset.hours;
        this._textDifficulty = this.rootElement.dataset.difficultyLabel;
        this._textTime = this.rootElement.dataset.timeLabel;
        this._urlDetail = this.rootElement.dataset.urlDetail;
        this._urlDefaultChefImage = this.rootElement.dataset.urlDefaultChefImage;
        this._difficultyMap = {
            1: this.rootElement.dataset.difficultyEasy,
            2: this.rootElement.dataset.difficultyMedium,
            3: this.rootElement.dataset.difficultyDifficult
        };
        this._difficultyMapTracking = {
            1: "low",
            2: "medium",
            3: "high"
        };
        this._showLoadingSpinner = null;
        this._currentPage = 1;
        this._textAddToFavorites = this.rootElement.dataset.addToFavorites;
        this._textRemoveFromFavorites = this.rootElement.dataset.removeFromFavorites;
        this._urlFavorites = this.rootElement.dataset.urlFavorites;

        /**
         * @type {Component}
         * @private
         */
        this._loadingSpinner = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-favorite-list__loading-spinner"));

        /**
         * @type {Component}
         * @private
         */
        this._appliedFiltersCount = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-favorite-list__applied-filters-count"));

        /**
         * @type {Component}
         * @private
         */
        this._loadingContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-favorite-list__loading-container"));

        /**
         * @type {Component}
         * @private
         */
        this._loadMoreContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-favorite-list__load-more-container"));

        /**
         * @type {Component}
         * @private
         */
        this._resultsList = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-favorite-list__results-list"));

        /**
         * @type {Component}
         * @private
         */
        this._resultsContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-favorite-list__results-container"));

        /**
         * @type {Component}
         * @private
         */
        this._noResultsContainer = ComponentFactory.getInstance(this.rootElement.querySelector(".lirc-o-favorite-list__no-results-container"));

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._loadMoreButton = ComponentFactory.getInstance(this.querySelector(".lirc-o-favorite-list__load-more-button"));
        this._loadMoreButton.clickSubject.subscribe(() => {
            this._currentPage++;
            this._setResultsDebounced();
        });

        /**
         * @type {Function}
         * @private
         */
        this._setResultsDebounced = EventUtils.debounce(() => this._setResults(), 30);

        this._setResultsDebounced();
    }

    /**
     * Build result card object to be passed to nunjucks.
     * @param data
     * @param id
     * @return {Promise<{component: string, hasShowOverflow: boolean, width: {md: string, sm: string, xs: string}, tag: string, embedded: [{textDifficultyLevel: *, component: string, htmlAttributes: {id: null}, textTime: (*|string), textTimeLabel: *, textDifficultyLevelLabel: *, objectPicture: {embeddedSources: [{urlSourceLow: *, component: string, viewport: string, urlSource: *, urlSource2x: *}], component: string, textAlternative, ratio: string}, textHeadline, objectAnchor: {component: string, urlReference: string}}]}>}
     * @private
     */
    async _resultCardObject(data, id = null, totalAmount, index) {
        const chef = data.chefs && data.chefs[0] || null;
        const chefAdditional = data.chefs && data.chefs[1] || null;
        let chefName = chef ? chef.name : null;
        let chefNameAdditional = chefAdditional ? `, ${chefAdditional.name}` : null;

        let chefAdditionalMeta = chefAdditional ? {
            textChefNameAdditional: chefAdditional.name || null,
            objectImageAdditional: {
                component: "nucleus/a-image",
                urlSource: chef.imageVariations && chef.imageVariations[1] && chef.imageVariations[1].storageUrl || this._urlDefaultChefImage,
                textAlternative: chefAdditional.name
            }
        } : null;

        let chefOverlay = chef ? {
            ...chefAdditionalMeta,
            component: "lidl-recipes/o-card-overlay-chef",
            textChefName: chef.name || null,
            textSeparator: "&",
            objectImage: {
                component: "nucleus/a-image",
                urlSource: chef.imageVariations && chef.imageVariations[0] && chef.imageVariations[0].storageUrl || this._urlDefaultChefImage,
                textAlternative: chef.name
            }
        } : null;

        let favoriteButton = this._urlFavorites ? {
            component: "lidl-recipes/m-favorite-button",
            textAdd: this._textAddToFavorites,
            textRemove: this._textRemoveFromFavorites,
            recipeId: data.id,
            urlFavorites: this._urlFavorites,
            isDeletedAfterRemove: true,
            gaRecipeName: data.name,
            gaChef: !chefName && !chefNameAdditional ? null : `${chefName}${chefNameAdditional}`,
            gaTime: data.cookingTime + data.preparationTime,
            gaQuantity: totalAmount,
            gaDifficulty: this._difficultyMapTracking[data.difficulty],
            gaPosition: index
        } : null;

        return {
            component: "nucleus/a-flex-item",
            tag: "li",
            hasShowOverflow: true,
            width: {
                xs: "12",
                sm: "6",
                md: "4"
            },
            embedded: [{
                component: "lidl-recipes/o-card",
                htmlAttributes: {
                    id: id || null
                },
                textHeadline: data.name,
                textDifficultyLevel: this._difficultyMap[data.difficulty],
                textDifficultyLevelLabel: this._textDifficulty,
                textTime: this._formatMinutes(data.cookingTime + data.preparationTime, this._textMinutes, this._textHours),
                textTimeLabel: this._textTime,
                objectPicture: {
                    component: "nucleus/m-picture",
                    ratio: "16/9",
                    textAlternative: data.name,
                    embeddedSources: [
                        {
                            component: "nucleus/a-source",
                            viewport: "xs",
                            urlSource: data.imageVariations[0].storageUrl,
                            urlSource2x: data.imageVariations[0].storageUrl,
                            urlSourceLow: data.imageVariations[0].storageUrl
                        }
                    ]
                },
                objectAnchor: {
                    component: "nucleus/a-anchor",
                    urlReference: `${this._urlDetail}/${data.slug}`
                },
                embeddedOverlay: [
                    chefOverlay,
                    favoriteButton
                ]
            }]
        };
    }

    /**
     * @returns {Promise<void>}
     * @private
     */
    async _setResults() {
        let result = await this._fetchRecipes();
        if(!result) return;

        let recipesAmount = parseInt(result["total"]) || 0;


        if (this._currentPage === 1) {
            this._resultsList.embeddedElement.innerHTML = "";
        }

        for (const [index, data] of result.recipes.entries()) {
            let id = index === 0 && `result-page-${result["page_number"]}`;
            this._resultsList.embeddedElement.insertAdjacentHTML(
                "beforeend",
                await NunjucksWebWrapper.renderComponent(
                    await this._resultCardObject(data, id, recipesAmount, index + 1)
                )
            );
            Factory.initAll(this._resultsList.rootElement);
        }

        /**
         * @type {LidlRecipesMFavoriteButton[]}
         * @private
         */
        let favoriteButtons = ComponentFactory.getInstances(this._resultsList.querySelectorAll(LidlRecipesMFavoriteButton.rootSelector));
        favoriteButtons.forEach(favoriteButton => {
            favoriteButton.removeButton.clickSubject.subscribe(() => {
                recipesAmount--;
                if (recipesAmount === 0) {
                    this._noResultsContainer.show();
                    this._resultsContainer.hide();
                }
            })
        })

        if (result["total_amount_of_pages"] == result["page_number"]) {
            this._loadMoreContainer.hide();
        } else {
            this._loadMoreContainer.show();
        }

        if (recipesAmount === 0) {
            this._noResultsContainer.show();
            this._resultsContainer.hide();
        } else {
            this._noResultsContainer.hide();
            this._resultsContainer.show();
        }
    }

    /**
     * @param response
     * @returns {Promise<*>}
     * @private
     */
    async _handleResponse(response) {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        if (!result || Object.keys(result).length === 0) {
            throw new Error('Result is empty');
        }
        return result;
    }

    /**
     * @returns {Promise<void>}
     * @private
     */
    async _fetchRecipes() {
        this._urlParams.set("page", this._currentPage.toString());
        this._isLoading(true);

        try {
            const url = new URL(`${this._urlFavorites}/${userService.userId}/favorites`);
            url.searchParams.set('page', this._currentPage.toString());
            url.searchParams.set('size', '999');

            let options = {
                method: "GET",
                headers: {
                    "Content-type": "application/json; charset=UTF-8",
                    "Authorization": `Bearer ${await userService.getAuthToken()}`
                }
            }

            return await this._handleResponse(await fetch(url, options));
        } catch (error) {
            this._noResultsContainer.show();
            this._resultsContainer.hide();
        } finally {
            this._isLoading(false);
        }
    }

    /**
     * Format minutes.
     * @param totalMinutes
     * @param minText
     * @param hoursText
     * @returns {*|string}
     * @private
     */
    _formatMinutes(totalMinutes, minText, hoursText) {
        if (totalMinutes <= 59) {
            return `${totalMinutes} ${minText}`;
        }

        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;

        if (minutes === 0) {
            return `${hours} ${hoursText}`;
        }

        return `${hours}:${minutes.toString().padStart(2, '0')} ${hoursText}`;
    }

    /**
     * Handle loading overlay.
     * @param isLoading
     * @private
     */
    _isLoading(isLoading) {
        if (isLoading) {
            this._loadingContainer.show();
            this._loadingSpinner.show();
            return;
        }
        this._loadingContainer.hide();
        this._loadingSpinner.hide();
    }
}

ComponentFactory.registerComponent(LidlRecipesOFavoriteList);
