import "@nucleus/polyfill/src/_polyfill";
import { Component, ComponentFactory, Factory } from "@nucleus/core/src/_core";
import { NunjucksWebWrapper } from "@nucleus-tools/nunjucks-web";

export default class LidlRecipesOMediaHero extends Component {

    /**
     * @inheritDoc
     */
    static get rootClassName() {
        return "lirc-o-media-hero";
    }

    /**
     * Creates an instance of LidlRecipesOMediaHero.
     *
     * @param {Element} rootElement
     */
    constructor(rootElement) {
        super(rootElement);

        this._videoId = rootElement.dataset.videoId;
        if (!this._videoId) return;

        this._videoContainer = rootElement.querySelector(".lirc-o-media-hero__video-container");

        /**
         * @type {Component}
         * @private
         */
        this._videoOverlay = ComponentFactory.getInstance(
            rootElement.querySelector(".lirc-o-media-hero__video-overlay")
        );

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._closeButton = ComponentFactory.getInstance(
            rootElement.querySelector(".lirc-o-media-hero__close-button")
        );

        /**
         * @type {NucleusAButton}
         * @private
         */
        this._playButton = ComponentFactory.getInstance(
            rootElement.querySelector(".lirc-o-media-hero__trigger-button")
        );

        this._playButton.clickSubject.subscribe(() =>
            this._showMediaOverlay()
        );

        this._closeButton.clickSubject.subscribe(() =>
            this._hideMediaOverlay()
        );
    }

    /**
     * Show/initialize media overlay
     * @returns {Promise<void>}
     * @private
     */
    async _showMediaOverlay() {
        this._videoContainer.innerHTML = await NunjucksWebWrapper.renderComponent(
            {
                component: "nucleus/a-media-youtube",
                htmlAttributes: {
                    class: "lirc-o-media-hero__video"
                },
                videoId: this._videoId,
                hasAutoplay: true
            }
        );
        Factory.initAll(this._videoContainer);

        this._videoOverlay.show();
        this._closeButton.focus();
    }

    /**
     * Hide media overlay.
     * @private
     */
    _hideMediaOverlay() {
        this._videoContainer.replaceChildren();
        this._videoOverlay.hide();
        this._playButton.focus();
    }
}

ComponentFactory.registerComponent(LidlRecipesOMediaHero);
